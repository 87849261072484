import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import {CommonModule} from '@angular/common';

import {StaticContentType} from '../../../../../../environments/environment';
import {StaticContentService} from '../../../../services/static-content.service';
import {UserTransactionsService} from '../../../../services/user/user-transactions.service';
import {ModalService} from '../../../../modal-v2/modal.service';
import { TranslatePipe } from '../../../translation/translate.pipe';

@Component({
  selector: 'app-buy-crypto',
  templateUrl: './buy-crypto.component.html',
  styleUrls: ['./buy-crypto.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslatePipe,
  ],
})
export class BuyCryptoComponent {

  public buyCryptoList$: Observable<any[]> = this._transactions.transactionList().pipe(
    filter((transactionsList: any[]) =>
      transactionsList?.length && transactionsList.every(item => !item.success) ||
      transactionsList?.length && !transactionsList.some(item => item.action === 'deposit' && item.success && item.brand !== 'coinspaid') ||
      transactionsList?.length && transactionsList.some(item => item.action === 'deposit' && item.success && item.brand === 'coinspaid')
    ),
    switchMap(() => this._static.list({
      type: StaticContentType.BUY_CRYPTO
    }))
  );

  constructor(
    private _static: StaticContentService,
    private _transactions: UserTransactionsService,
    private _modal: ModalService
  ) {
  }

  public async showBuyCryptoModal(data: any) {
    const component = await import('../buy-crypto-modal/buy-crypto-modal.component');
    await this._modal.openLazy(component?.BuyCryptoModalComponent, {
      template: 'CLEAR',
      data
    });
  }
}
