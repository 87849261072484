<div class="crypto">
  @if (field?.fieldName !== 'tag') {
    <p class="crypto__desc">{{'t.private-dep-address' | translate}}</p>
    <div class="crypto__address">
      <div #address [innerHTML]="field?.address | safe : 'html'"></div>
      <button class="btn btn--primary big" (click)="copy(address, field?.address)">{{'btn.copy-adress' | translate}}</button>
    </div>
    <div class="crypto__qr-code">
      <div #accordion=accordionDirective Accordion class="crypto__more">{{ 't.qr-code' | translate }}
        <i class="icon-pointer-down" [class.rotated]="accordion.isOpen"></i>
      </div>
      <div class="crypto__image">
        <a [href]="field?.address | safe: 'url'" target="_blank">
          <img [src]="field?.address | generateQrBase64 | async">
        </a>
      </div>
    </div>
  } @else {
    <p #address [innerHTML]="field?.address | safe : 'html'"></p>
    <button class="btn btn--primary big" (click)="copy(address, field?.address)">{{'btn.copy-tag' | translate}}</button>
  }

</div>
