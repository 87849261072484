import { Injectable } from '@angular/core';
import {BasePromo, EventType, WelcomePromoModal} from '../base-promo';

@Injectable({
  providedIn: 'root'
})
export class AskgamblersService extends BasePromo {

  eventName = 'askgamblers';

  constructor() {
    super();
  }

  public openHuntModal(type: EventType) {
  }

  public checkActivatedBonuses(data: WelcomePromoModal) {}
}
