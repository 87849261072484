/**
 * Interface for CMS Jackpot
 */
import {Observable} from 'rxjs';

export interface IJackpotCMS {
  TopBgImage: string;
  TopBgImageMob: string;
  LeftHeaderImage: string;
  RightHeaderImage: string;
  JackpotLogo: string;
  Title: string;
  Levels: any[];
  Description: string;
  BenefitsTitle: string;
  Benefits: string;
  PlayTitle: string;
  WorksTitle: string;
  Works: any[];
  Terms: string;
  Rules: string;
  WinnersBg: string;
  SeoContent?: string;
  WinnersTitle?: string;
  WinnersList?: any[];
  name?: string;
}

/**
 * Interface for Jackpot Level
 */
export interface IJackpotLevel {
  amount_cents: number;
  available_amount_cents: number;
  current_award_value: number | string;
  id: number;
  identifier: string;
  index: number;
  name: string;
  periods: any[];
  state: string;
  total_amount_cents: number;
  working_days: any[];
  total?: number;
}

/**
 * Interface for Jackpot SS
 */
export interface IJackpotSS {
  allowed_currencies: string[];
  currency: string;
  external_id: string;
  games: string[];
  id: number;
  identifier: string;
  levels: IJackpotLevel[];
  name: string;
  state: string;
  total?: number;
  currencySymbol?: string;
  winners$?: Observable<any>;
}

/**
 * Enum Jackpot Levels
 */
export enum JackpotLevels {
  GRAND = 'Grand',
  MEGA = 'Mega',
  MAJOR = 'Major'
}

export interface IJackpotWinner {
  place?: number;
  name?: string;
  prize?: string | number;
  isCurrentPlayer?: boolean;
}

