import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  OnChanges,
  SimpleChanges,
  EventEmitter, Output, HostListener,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Observable, of} from 'rxjs';
import {tap, map, filter, catchError, switchMap, finalize, distinctUntilChanged, first} from 'rxjs/operators';
import {ToastMessageService} from 'src/app/core/modules/toast-message/toast-message.service';
import {ArrayService} from 'src/app/core/services/array.service';
import {EnvironmentService} from 'src/app/core/services/environment.service';
import {FormsErrorHandlerService} from 'src/app/core/services/forms-error-handler.service';
import {
  TRANSACTIONS_TYPES,
  UserTransactionsService
} from 'src/app/core/services/user/user-transactions.service';
import {UserService} from 'src/app/core/services/user/user.service';
import {AccordionDirective} from 'src/app/core/shared/directives/accordion.directive';
import {
  BONUS_CODE_QUERY_PARAM_NAME,
  SUCCESS_QUERY_PARAM_NAME
} from 'src/app/core/vendor/ss-payments-v2/ss-payment-config';
import {SsPaymentsV2Service} from 'src/app/core/vendor/ss-payments-v2/ss-payment.service';
import {
  CustomPaymentType, FastasiaAbTestLogo,
  LibError,
  Payment,
  PaymentsMethod
} from 'src/app/core/vendor/ss-payments-v2/ss-payment-types';
import {PaymentFormComponent} from '../payment-form/payment-form.component';
import {BreakpointsDetectorService} from '../../../../../../core/services/breakpoints-detector.service';
import {UserPaymentSsV2Service} from '../../../../../../core/services/user/user-payment-ss-v2.service';
import {LotteryService} from '../../../../../../core/services/lottery.service';
import {CryptoCurrenciesConverterService} from '../../../../../../core/services/crypto-currencies-converter.service';
import {
  GoogleTagManagerService, POPUP_EVENT_ACTIONS,
  POPUP_EVENT_CATEGORY
} from '../../../../../../core/services/google-tag-manager.service';
import {WindowService} from '../../../../../../core/services/window.service';
import {ModalService} from '../../../../../../core/modal-v2/modal.service';
import {
  AsyncPipe,
  NgClass,
  NgStyle,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
  NgTemplateOutlet,
} from '@angular/common';
import { TranslatePipe } from '../../../../../../core/shared/translation/translate.pipe';
import { DepositBonusComponent } from '../deposit-bonus/deposit-bonus.component';
import { LotteryDepositComponent } from '../loterry-deposit/lottery-deposit.component';
import { PreloaderComponent } from '../../../../../../core/shared/components/preloader/preloader.component';
import { BuyCryptoComponent } from '../../../../../../core/shared/standalone/payment/buy-crypto/buy-crypto.component';
import { DefaultImage } from '../../../../../../core/shared/directives/default-image.directive';
import { ClassLoadedDirective } from '../../../../../../core/shared/directives/class-loaded.directive';
import { isMobile } from '../../../../../../core/helpers/device';
import { AbTestNewService } from '../../../../../../core/ab-test/ab-test.service';
import { AB_TEST_LIST } from '../../../../../../core/ab-test/ab-test.data';
/**
 * Countries which required phone number for successful transaction
 */
export const PHONE_REQUIRED_COUNTRIES = ['NZ', 'CA', 'JP', 'AU', 'PL', 'BR', 'DE', 'NO', 'IE', 'AT', 'CH', 'DK', 'KW', 'AE', 'QA', 'AR'];

export const APPLEPAY = 'applepay';

@UntilDestroy()
@Component({
  selector: 'app-payment-ss',
  templateUrl: './payment-ss.component.html',
  styleUrls: ['./payment-ss.component.scss'],
  imports: [
    NgSwitch,
    NgSwitchCase,
    NgTemplateOutlet,
    AsyncPipe,
    TranslatePipe,
    NgStyle,
    NgClass,
    DepositBonusComponent,
    LotteryDepositComponent,
    PreloaderComponent,
    BuyCryptoComponent,
    AccordionDirective,
    NgSwitchDefault,
    DefaultImage,
    ClassLoadedDirective,
    PaymentFormComponent,
  ],
  standalone: true,
})
export class PaymentSsComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @HostListener('click', ['$event'])
  handleClick() {
    this._payment.isDepositAction = true;
  }

  @Input('action') Action: Payment.Action;
  @Input('isModal') isModal: boolean;

  @Output() isCryptoSelected$: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('accordion', { read: AccordionDirective }) accordion: AccordionDirective;
  @ViewChild('bonusAccordion', {read: AccordionDirective}) bonusAccordion: AccordionDirective;
  @ViewChild(PaymentFormComponent) paymentForm: PaymentFormComponent;


  protected readonly ActionTypes = Payment.Action;
  protected readonly Payment = Payment;

  /**
   * User for input in child component for set in bonus code form
   */
  public bonusCode: string;

  /**
   * User for input in child component for set success url after deposit
   */
  public successUrl: string;

  public CustomDepositRoutes = {
    successUrl: `${location.origin}/payment-status/deposit/success`,
    failureUrl: `${location.origin}/payment-status/deposit/failure`,
    supportUrl: `${location.origin}/faq`,
    pendingUrl: `${location.origin}/profile/history`,
  };

  /**
   * Is payment in progress
   */
  public paymentInProgress: boolean;

  /**
   * Is transaction canceling in progress
   */
  public cancelingProcess = false;

  public readonly FastasiaLogoPhpB: FastasiaAbTestLogo = FastasiaAbTestLogo.VERSION_PHP_B;

  public readonly FastasiaLogoThb: FastasiaAbTestLogo = FastasiaAbTestLogo.VERSION_THB;

  public lastSelectedMethodId: string;

  public brandFromQueryParam: string;

  private _isCard: boolean;

  private _interacImgCashout = 'https://cdn2.softswiss.net/logos/payments/color/finteqhub-interac_finteqhub_cashout.svg';

  /**
   * Selected payment method
   */
  private _selectedPaymentMethod: PaymentsMethod;

  /**
   * List of payment methods
   */
  public paymentList$: Observable<PaymentsMethod[]> = this._payment.paymentMethodsList$.pipe(
    distinctUntilChanged(this._array.isEqual),
    tap((list) => {
      this._selectedPaymentMethod = null;
    }),
    tap(list => {
      if ((!list || !list.length)) {
        if (list === null) {
          return;
        }
        this._showNoPaymentModal();
      }
    }),
    map(list => list && list.map(item => {
      return {
        ...item,
        img: this.Action === Payment.Action.Cashout &&
        item.brand === 'interac_finteqhub' ? this._interacImgCashout : item.iconSrc,
      };
    })),
    map(list => list && list.filter(method => {
      return method.brand !== APPLEPAY || (method.brand === APPLEPAY && (this._window.isiOSDevices || this._window.isSafari || this._window.isIpadOsDevices));
    })),
    tap(list => {
      if (!list) {
        return;
      }

      /**
       * Select method by default
       */
      const methodWithSavedAccount = list.find(method => method?.savedProfiles);
      const cryptoMethod = list.find(method => method.type === 'crypto');

      let firstAvailableMethod;

      if (this.user.isHasCryptoAccounts() && cryptoMethod) {
        firstAvailableMethod = cryptoMethod;

        if (list.length > 3 && this.accordion) {
          setTimeout(() => {
            this.accordion.open();
          });
        }
      } else {
        firstAvailableMethod = list[0];
      }

      let methodToSelect = methodWithSavedAccount || firstAvailableMethod;

      if (this.brandFromQueryParam) {
        const indexMethodToSelectByQueryParams = list?.findIndex(p =>
          p?.brand === 'coinspaid' && this.brandFromQueryParam !== 'coinspaid' ?
            `${p?.currency}_${p?.brand}` === this.brandFromQueryParam :
            p?.brand === this.brandFromQueryParam);
        methodToSelect = list[indexMethodToSelectByQueryParams] || methodToSelect;
      }
      this.selectPayment(methodToSelect);

      this.lastSelectedMethodId = methodToSelect?.id;
    }),
  );

  /**
   * Latest cashout transaction to recall it
   */
  public latestCashout$: Observable<any> = this.transactions.transactionList().pipe(
    map(list => list.filter(tr => tr.action === TRANSACTIONS_TYPES.CASHOUT && tr.recallable)),
    map(list => list.sort((a, b) => b.id - a.id))
  );

  constructor(
    private _ssPayments: SsPaymentsV2Service,
    private _payment: UserPaymentSsV2Service,
    private _toastMessage: ToastMessageService,
    private _array: ArrayService,
    private _formErrors: FormsErrorHandlerService,
    private _route: ActivatedRoute,
    private _window: WindowService,
    private _converter: CryptoCurrenciesConverterService,
    private _gtm: GoogleTagManagerService,
    private _router: Router,
    private _modal: ModalService,
    public abTest: AbTestNewService,
    public breakpoints: BreakpointsDetectorService,
    public user: UserService,
    public env: EnvironmentService,
    public transactions: UserTransactionsService,
    public lottery: LotteryService,
  ) {
    this._resolveQueryParams();
  }

  /**
   * Is selected payment method is crypto
   */
  public get isSelectedPaymentMethodCrypto(): boolean {
    return this._selectedPaymentMethod?.type === Payment.MethodType.Crypto;
  }


  /**
   * Active payment method
   */
  public get selectedMethod(): PaymentsMethod {
    return this._selectedPaymentMethod;
  }
  public get isCard() {
    return this._isCard;
  }

  get isInteracPayment(): boolean {
    return this._selectedPaymentMethod?.brand === 'interac_finteqhub'
      || this._selectedPaymentMethod?.brand === 'combined'
      || this._selectedPaymentMethod?.brand === 'etransfer';
  }

  get isWebPay(): boolean {
    return this._selectedPaymentMethod && this._selectedPaymentMethod.brand === 'webpay';
  }

  ngOnInit() {
    this._handleUserCurrencyChange();
    this._resolveBonusCode();
  }

  ngAfterViewInit() {
    if (this.bonusCode) {
      this.bonusAccordion.open();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.Action?.firstChange) {
      if (this.Action === Payment.Action.Deposit) {
        this._gtm.popupEvent(POPUP_EVENT_CATEGORY.POPUP_DEPOSIT, POPUP_EVENT_ACTIONS.POPUP_DEPOSIT);
      }
    }

    if (this.Action) {
      this._payment.currentPaymentAction = this.Action;
    }
  }

  ngOnDestroy() {
    this._payment.currentPaymentAction = null;
    this._payment.paymentMethodsList$.next(null);
  }


  switchView(event: boolean) {
    this.isModal = event;
  }

  /**
   * Select payment method
   * @param method
   */
  public selectPayment(method: PaymentsMethod, index?: number) {
    this._payment.hidePlayerFieldsInPaymentForm = false;
    this._payment.isSecondDepositStep = false;
    this._selectedPaymentMethod = method;
    this._onSelectPaymentLogic(method, index);
  }

  private _checkActiveDepositBtn(paymentMethod: PaymentsMethod) {
    this.transactions.transactionList$.pipe(
      filter(() => AB_TEST_LIST.DEVSS5602.resolvedValue === AB_TEST_LIST.DEVSS5602.abTestGroupIds.V2),
    ).subscribe((transactions) => {
      this.transactions.handleFailedTransactions(transactions, paymentMethod);
    });
  }

  /**
   * Check credit card and add phone number field
   * @param method
   * @param e
   * @private
   */
  private _checkCreditCard(method: PaymentsMethod) {
    this._isCard = method?.type === 'cards' && PHONE_REQUIRED_COUNTRIES.some(item => item === this.user.info.country);
  }

  /**
   * Resolve bonus code
   * @private
   */
  private _resolveBonusCode() {
    this._route.queryParamMap.pipe(
      untilDestroyed(this),
      filter(params => params && params.has(BONUS_CODE_QUERY_PARAM_NAME) || params.has(SUCCESS_QUERY_PARAM_NAME)),
      tap(params => {
        this.bonusCode = params.get(BONUS_CODE_QUERY_PARAM_NAME);
        this.successUrl = params.get(SUCCESS_QUERY_PARAM_NAME);
        if (this.successUrl) {
          this.CustomDepositRoutes = {...this.CustomDepositRoutes, successUrl: this.successUrl};
        }
      }),
    ).subscribe();
  }

  /**
   * Handle deposit payment form submit
   */
  submitDeposit(paymentData, paymentType?: CustomPaymentType) {
    this.paymentInProgress = true;

    this._ssPayments.submitForm(
      this.selectedMethod.id,
      this.selectedMethod.currency,
      this.Action,
      paymentType,
      paymentData?.formData,
      paymentData?.savedProfileId?.id || null,
      paymentData?.playerFieldsData,
    ).pipe(
      catchError((error: LibError.SubmitError) => {
        console.log(error);
        this._resolvePaymentFieldErrors(paymentData, error);
        return of(error);
      }),
      tap(e => this._resolvePaymentMessage(e)),
      switchMap(() => this.user.getUserInfo()),
      finalize(
        () => {
          this.paymentInProgress = false;
        }
      )
    ).subscribe();
  }

  /**
   * Handle cashout payment form submit
   */
  submitCashout(paymentData, paymentType?: CustomPaymentType) {
    this.paymentInProgress = true;
    this._ssPayments.submitForm(
      this.selectedMethod.id,
      this.selectedMethod.currency,
      this.Action,
      paymentType,
      paymentData?.formData,
      paymentData?.savedProfileId?.id || null,
      paymentData?.playerFieldsData,

    ).pipe(
      catchError((error: LibError.SubmitError) => {
        this._resolvePaymentFieldErrors(paymentData, error);
        return of(error);
      }),
      tap(e => this._resolvePaymentMessage(e)),
      switchMap(() => this.user.getUserInfo()),
      finalize(() => {
        this.paymentInProgress = false;
      })
    ).subscribe();
  }

  /**
   * Cancel button click handler
   *
   * @param id
   */
  cancelClick(id: string) {
    this.cancelingProcess = true;
    this.transactions.cashoutRecall(id).pipe(
      tap(() => {
        this._toastMessage.success('t.latest-transaction');
      }),
      catchError(error => {
        this._toastMessage.error('t.error');
        return of(error);
      }),
      switchMap(() => this.user.getUserAccounts()),
      finalize(() => this.cancelingProcess = false)
    ).subscribe();
  }

  /**
   * Update payment methods list on user currency change
   */
  private _handleUserCurrencyChange(): void {
    this.user.currency$.pipe(
      untilDestroyed(this),
      tap(currency => this._payment.updatePaymentMethodsList(currency))
    ).subscribe();
  }

  /**
   * The function `_resolvePaymentMessage` displays a toast message if the payment status is 'notify' and
   * the action is 'Cashout'.
   * @param e - The parameter "e" is an object that represents a payment event. It may have a "status"
   * property that indicates the status of the payment.
   */
  private _resolvePaymentMessage(e) {
    console.log(e);
    if (e?.status === 'notify') {
      if (this.Action === Payment.Action.Cashout) {
        this._toastMessage.info('t.cashout-request-received');
      }
    }
  }

  /**
   * The function `_resolvePaymentFieldErrors` handles and displays errors related to payment fields and
   * updates user information if necessary.
   * @param paymentData - `paymentData` seems to be an object containing data related to a payment
   * process. It likely includes information about the payment form, player fields data, and possibly
   * other payment-related details.
   * @param error - The `_resolvePaymentFieldErrors` function takes two parameters: `paymentData` and
   * `error`. The `error` parameter is of type `LibError.SubmitError`. This function is responsible for
   * handling errors related to payment fields. It checks the type of error and then processes any common
   * errors, method
   */
  private _resolvePaymentFieldErrors(paymentData, error: LibError.SubmitError) {
    if (error.type === LibError.Type.Params || error.type === LibError.Type.Request) {
      if (error.commonErrors?.length || error?.methodFieldsErrors || error?.playerFieldsErrors || error.amountFieldErrors) {
        this._array.fromObjectValues(error.commonErrors || error.methodFieldsErrors || error?.playerFieldsErrors || error.amountFieldErrors)
          .forEach(currentError => this._toastMessage.error(currentError));
        const fieldErrors = {
          ...(error?.methodFieldsErrors || {}),
          ...(error?.playerFieldsErrors || {}),
          ...(error?.amountFieldErrors || {})
        };
        this._formErrors.applyFormErrors(paymentData.form, {errors: {...fieldErrors}}, true);
      }
    }
    if (Object.keys(paymentData?.playerFieldsData)?.length && !error?.playerFieldsErrors) {
      this.user.applyUserInfo({...this.user.info, ...paymentData?.playerFieldsData});
      this._payment.hidePlayerFieldsInPaymentForm = true;
    } else {
      this._payment.hidePlayerFieldsInPaymentForm = false;
    }
  }

  private _onSelectPaymentLogic(method: PaymentsMethod, index?: number) {
    /**
     * Start logic for order payment when clicked
     */
    const checkedIndex = isMobile() ? 1 : 2;
    if (((index > checkedIndex || !!this.lastSelectedMethodId && index === checkedIndex)) && this.accordion.isOpen) {
      this.lastSelectedMethodId = null;
      this.lastSelectedMethodId = method?.id;
    }
    /**
     * End logic for order payment when clicked
     */

    if (this.accordion?.isOpen) {
      this.accordion?.close();
    }

    this.isCryptoSelected$.emit(this.isSelectedPaymentMethodCrypto);

    this._checkCreditCard(method);

    if (method?.type === Payment.MethodType.Crypto) {
      this._converter.refreshRate({
        from: `${ this.selectedMethod?.termsOfService?.restrictions?.amountCurrencyCode }`,
        to: `${ this.user.currentCurrency.currency.toUpperCase() }`
      });
    }

    this._checkActiveDepositBtn(method);
  }

  /**
   * Resolve query params
   * @private
   */
  private _resolveQueryParams() {
    this._route.queryParams.pipe(
      untilDestroyed(this),
      first(),
      tap((data) => this.brandFromQueryParam = data?.brand),
    ).subscribe();
  }

  /**
   * Show no payment modal for change currency
   * @private
   */
  private async _showNoPaymentModal() {
    const component = await import('../../../../../../core/modal-v2/components/lazy/no-payments-modal/no-payments-modal.component');
    const modal = await this._modal.openLazy(component?.NoPaymentsModalComponent, {
      template: 'CLEAR',
      data: {
        action: this.Action,
      },
    });
    modal.onResult().pipe(
      filter(isClose => !!isClose),
      tap(() => this._router.navigateByUrl('/').then()),
    ).subscribe();
  }

  protected readonly isMobile = isMobile;
}
