import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { WindowService } from '../../../services/window.service';


@Component({
    selector: '.btn',
    templateUrl: './btn.component.html',
    styleUrls: ['./btn.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.loading]': `loading`,
        '[class.apple]': `appleDevices`,
        '[class.disabled]': `disabled`,
        '[class.gradient]': `gradient`,
        '[attr.disabled]': `disabled || loading || null`,
    },
    standalone: true,
    imports: []
})
export class BtnComponent implements OnInit {

  @Input('loading') loading = false;

  @Input('disabled') disabled = false;

  @Input('gradient') gradient = false;

  constructor(
    private _window: WindowService
  ) {
  }

  get appleDevices() {
    return this._window.isSafari || this._window.isiOSDevices;
  }

  ngOnInit(): void {}

}
