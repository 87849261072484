export const ZENDESK_SCRIPT_URL = `https://static.zdassets.com/ekr/snippet.js`;
export const DEFAULT_DEPARTMENTS_LIST = [
  'Bonus issues SC', 'Deposit Assistance SC', 'High Roller promotions SC', 'Other issues SC',
  'Reopen account SC', 'Technical issue SC', 'Verification process SC', 'Withdrawal SC', 'Extra bonus SC'
];
export const VIP_DEPARTMENT = 'Samurai_dedicated';
export const FIVE_DOLLARS_DEPARTMENT = '5$_Samurai';

export const VIP_CHAT_EXPIRE_AT_COOKIE = 'vipChatExpireAt';

export const ONLINE_CHAT_COUNTRIES = ['au', 'de', 'ca', 'nz', 'fi', 'at', 'dk', 'ie', 'jp', 'it', 'br'];

export const zendeskStag = '666';

export const FIVE_DOLLARS_STAGS = [
  '107674', '107672', '107671', '107670',
  '107669', '107668', '107666', '107665',
  '107664', '107663', '107662', '107661',
  '107675', '107660', '107659', '105970'
];

export const SPECIFIC_STAGS_CHAT = [
  '98430', '102378', '86298', '86656',
  '107671', '86293', '106300', '87831',
  '106670', '96364', '87556', '102465',
  '89398', '107639', '97694', '100017',
  '99316', '107436', '92954', '93078',
  '114117', '93154', '92725', '103391', '111501'
];

export const HIDE_CHAT_FOR_STAGS = ['125849', '129048'];

export interface IZendeskUserData {
  name?: string;
  email?: string;
};
