import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SsApiService } from '../../services/api/ss-api.service';
import {CRYPTO_GROUP, UserService} from '../../services/user/user.service';
import { Router } from '@angular/router';
import { ValidationPatterns } from '../validation-patterns';
import {catchError, debounceTime, first, switchMap, tap} from 'rxjs/operators';
import { EMPTY, forkJoin, of } from 'rxjs';
import { CommonDataService, CRYPTO_ACCOUNTS, isCryptoAcc } from '../../services/common-data.service';
import { EnvironmentService } from '../../services/environment.service';
import { PlatformService } from '../../services/platform.service';
import { ToastMessageService } from '../../modules/toast-message/toast-message.service';
import { CacheService } from '../../services/cache/cache.service';
import { ContentUpdaterService } from '../../services/content-updater/content-updater.service';
import { FormsErrorHandlerService } from '../../services/forms-error-handler.service';
import { CustomValidators } from '../custom-validators';
import { EventEmitter, inject } from '@angular/core';
import { GoogleTagManagerService } from '../../services/google-tag-manager.service';
import { UserInfoService } from '../../services/user/user-info.service';
import { GroupsService } from '../../services/groups.service';
import { CookieService } from 'ngx-unificator/services';
import { LocalstorageService } from '../../services/localstorage.service';
import {ModalService} from '../../modal-v2/modal.service';
import {ArabicService} from '../../services/arabic.service';
import {LoyaltyService} from '../../services/loyalty/loyalty.service';
import { UbidexService } from '../../services/ubidex.service';
import {decodeFromBase64, decodeHex, encodeToBase64, encodeToHex} from "../utils";
import { AbTestNewService } from '../../ab-test/ab-test.service';
import {UserSubscriptionsService} from "../../services/user/user-subscriptions.service";
import {LocalHistoryService} from "../../services/local-history.service";
import { AB_TEST_LIST } from '../../ab-test/ab-test.data';

const fieldsToSaveLocalStorage = ['email', 'password', 'password_confirmation', 'nickname'];

export const registrationStorageName = '__registrationData';

export class RegisterFormController {

  /**
   * Access to global Services
   */
  private _fb: UntypedFormBuilder = inject(UntypedFormBuilder);
  private _ssApi: SsApiService = inject(SsApiService);
  private _user: UserService = inject(UserService);
  private _userInfo: UserInfoService = inject(UserInfoService);
  private _router: Router = inject(Router);
  private _commonData: CommonDataService = inject(CommonDataService);
  private _env: EnvironmentService = inject(EnvironmentService);
  private _platform: PlatformService = inject(PlatformService);
  private _toastMessage: ToastMessageService = inject(ToastMessageService);
  private _cache: CacheService = inject(CacheService);
  private _cookies: CookieService = inject(CookieService);
  private _contentUpdater: ContentUpdaterService = inject(ContentUpdaterService);
  private _formErrors: FormsErrorHandlerService = inject(FormsErrorHandlerService);
  private _gtm: GoogleTagManagerService = inject(GoogleTagManagerService);
  private _modal: ModalService = inject(ModalService);
  private _groups: GroupsService = inject(GroupsService);
  private _localStorage: LocalstorageService = inject(LocalstorageService);
  private _rtl: ArabicService = inject(ArabicService);
  private _loyalty: LoyaltyService = inject(LoyaltyService);
  private _ubidex: UbidexService = inject(UbidexService);
  private _abTestNew: AbTestNewService = inject(AbTestNewService);
  private _subscriptions: UserSubscriptionsService = inject(UserSubscriptionsService);
  private _local: LocalHistoryService = inject(LocalHistoryService);

  /**
   * Is loading state
   */
  private _loading: boolean;

  /**
   * Arrow for dropdown state
   */
  private _showArrow: boolean;

  /**
   * Emits updating result
   */
  public registered$: EventEmitter<boolean> = new EventEmitter<boolean>();
  public error$: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Form errors
   */
  public countryAndNicknameErrors: string[] = [];

  /**
   * Login for FormGroup
   */
  private _form: UntypedFormGroup = this._fb.group({
    email: ['', [Validators.required, Validators.pattern(ValidationPatterns.email)]],
    password: ['', [Validators.required,  CustomValidators.changeKey('password_pattern', Validators.pattern(ValidationPatterns.password))]],
    password_confirmation: ['', [Validators.required]],
    nickname: ['', [Validators.required, CustomValidators.checkSpaces]],
    country: [null, [CustomValidators.changeKey('required_country', Validators.required)]],
    currency: [null, [Validators.required]],
    receive_promos: [true],
    receive_sms_promos: [false],
    terms_acceptance: [false, [Validators.required]],
    age_acceptance: [false, [Validators.required]],
    bonus_code: [null, Validators.pattern(ValidationPatterns.lettersAndNumbers)],
  }, {
    validators: [CustomValidators.mustMatch('password', 'password_confirmation')],
  });

  /**
   * True if ptag present
   */
  public isPtag = false;

  constructor() {
    this._setAutoDetectedValues();
    this._pasteDataFromStorageToForm();
    this.isPtag = this._cookies.check('ptag');
    this._resolveValueByGeo();
  }

  /**
   * Access to private properties from outside
   */
  get form(): UntypedFormGroup { return this._form; }
  get loading(): boolean { return this._loading; }
  get showArrow(): boolean { return this._showArrow; }

  /**
   * Returns FormControl by key
   *
   * @param key
   */
  input(key: string): AbstractControl {
    return this._form.get(key);
  }

  /**
   * Submit form handler
   */
  submitForm(form: UntypedFormGroup) {
    this._setAdditionalFields(form);

    const errors = this._formErrors.applyFormErrors(form, null, true);

    if (!isCryptoAcc(form.value.currency)) {
      this.countryAndNicknameErrors = [
        ...errors.country,
        ...errors.nickname,
      ];
    }

    if (form.invalid) {
      return;
    }

    this._loading = true;

    const bonusCode = form.getRawValue().bonus_code;

    const value = {
      ...form.getRawValue(),
      country: CRYPTO_ACCOUNTS.includes(form.value.currency) ?
        this._env.env.country.short.toUpperCase() :
        form.value.country.toUpperCase(),
      receive_promos: true,
      bonus_code: bonusCode ? bonusCode.toUpperCase() : bonusCode
    };

    this._ssApi.usersSignUp({
      user: value
    }).pipe(
      tap(response => {
        this._cache.clear();
        this._user.applyUserInfo(response);
        this._addUserToCryptoGroup(response);
        this._ubidex.onRegister(response);
        this._addUserToSamuraiGroupIfRtl();

        forkJoin([
          this._user.getUserAccounts(),
          this._user.getUserAccountsCompatibility()
        ]).subscribe();

        this._contentUpdater.updateAll();

        this._gtm.signUpSuccessTimestamp('reg_succ_time', 'reg_succ', response.id);
        this._gtm.signUpSuccess(response.id);

        this.registered$.next(true);

        this.toggleRegistrationDataToStorage(true);

        this._handleRedirects();
      }),
      tap(async () => await this._handleNewUsersTest()),
      switchMap(() => !isCryptoAcc(form.value.currency) ? this._userInfo.updatePlayerInfo({
        nickname: form.value.nickname},
        'edition') : of(null)),
      switchMap(() => !isCryptoAcc(form.value.currency) ? this._user.getUserInfo() : of(null)),
      switchMap(() => this._rtl.isAr ? EMPTY : this._userInfo.missingAttributesFor('deposit')),
      catchError(error => {
        this._toastMessage.error('t.error-check-data');
        this._gtm.registerError('register_error', error.error.errors);
        this.error$.next(error);
        this._formErrors.applyFormErrors(form, {
          errors: {
            ...error.error.errors || {},
            ...error.error.errors && error.error.errors.profile || {},
          },
        }, true);
        return of(error);
      })
    ).subscribe(() => {
      this._loading = false;
    });
  }

  /**
   * Set values to fields that not exists in form
   *
   * @private
   */
  private _setAdditionalFields(form: UntypedFormGroup) {
    form.get('age_acceptance')?.setValue(form.get('terms_acceptance').value);
    form.get('password_confirmation')?.setValue(this._form.get('password').value);
  }

  /**
   * Automatically set known values for register form
   *
   * @private
   */
  private _setAutoDetectedValues() {
    if (!this._platform.isBrowser) {
      return;
    }

    this._commonData.loaded$.pipe(
      debounceTime(100),
      tap(() => {
        if (this._env.env.currency.short && this._commonData.currencyList.some(currency => currency.code === this._env.env.currency.short)) {
          this.form.get('currency').setValue(this._env.env.currency.short);
        } else {
          this.form.get('currency').setValue('EUR');
        }

        if (this._env.env.country.short && this._commonData.countryList.some(country => country.short === this._env.env.country.short)) {
          this.form.get('country').setValue(this._env.env.country.short);
        } else {
          this._showArrow = true;
          // this.form.get('country').setValue(this._commonData.countryList[0].short);
        }
      })
    ).subscribe();
  }

  /**
   * Add user to crypto group if has crypto currency after register
   * @param response
   * @private
   */
  private _addUserToCryptoGroup(response: any) {
    if (isCryptoAcc(response.currency)) {
      this._groups.addToGroup(CRYPTO_GROUP).pipe(
      ).subscribe();
    }
  }

  /**
   * Add user to Samurai loyalty group if rtl.isAr
   * @private
   */
  private _addUserToSamuraiGroupIfRtl() {
    if (this._rtl.isAr) {
      this._groups.addToGroup(this._loyalty.Group.SAMURAI).subscribe();
    }
  }

  /**
   * Toggle cookies enter data for set if user from terms
   *
   * @param isRemove
   * @param step
   */
  public toggleRegistrationDataToStorage(isRemove: boolean = false, step: number = 0) {
    if (!isRemove) {
    const regData: any = {};

    fieldsToSaveLocalStorage.forEach(field => regData[field] = this.input(field).value);
    regData.step = step || null;
    this._localStorage.set(registrationStorageName, encodeToHex(encodeToBase64(JSON.stringify((regData)))));
    } else {
      this._localStorage.clearItem(registrationStorageName);
    }
  }

  /**
   * Paste data in modal registration from localStorage
   * @private
   */
  private _pasteDataFromStorageToForm() {
    if (this._localStorage.get(registrationStorageName)) {
      const data = JSON.parse(decodeFromBase64(decodeHex(this._localStorage.get(registrationStorageName))));
      if (data) {
        Object.entries(data).forEach(value => {
          this.form.patchValue({[value[0]]: value[1]});
        });
      }
    }
  }

  private _handleRedirects() {
    if (Array.from(this._local.routes.keys()).some(key => key.includes('promo'))) {
      this._router.navigateByUrl(this._local.getUrl('promo').url);
    } else {
      this._globalRedirects();
    }
  }
  private _globalRedirects() {
    // If this._rtl.isAr navigate to deposit and after this._userInfo.missingAttributesFor('deposit')
    this._router.navigateByUrl(this._rtl.isAr ? '/deposit' : '/loyalty-program').then(() => {
      this._toastMessage.success('t.register-success');
      this._localStorage.clearItem(registrationStorageName);
    });
  }

  private async _handleNewUsersTest() {
    if (AB_TEST_LIST.DEVSS5757.resolvedValue === AB_TEST_LIST.DEVSS5757.abTestGroupIds.V2) {
      const component = await import('../../../core/modal-v2/components/lazy/new-users-modal/new-users-modal.component');
      return await this._modal.openLazy(component?.NewUsersModalComponent, {
        template: 'CLEAR',
      });
    }
  }

  private _resolveValueByGeo() {
    this._subscriptions.receiveEmailPromosValue$.pipe(
    ).subscribe((e) => {
      this.form.get('receive_promos').setValue(e);
    });

    this._subscriptions.acceptedTermsValue$.pipe(
    ).subscribe((e) => {
      this.form.get('age_acceptance').setValue(e);
      this.form.get('terms_acceptance').setValue(e);
    });
  }
}
