import { AfterViewInit,
  Component,
  ElementRef, EventEmitter, Input, OnInit, Output ,
  QueryList,
  ViewChild,
  ViewChildren} from '@angular/core';
import { UserService } from '../../../../core/services/user/user.service';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { BONUS_TYPES } from '../../../../page/bonuses/bonuses.component';
import { TimerComponent } from '../timer/timer.component';
import {
  CommonBonusInterface
} from '../../../../page/account/account-settings/account-tab/interfaces/common-bonus.interface';
import { ModalService } from '../../../modal-v2/modal.service';
import { BonusStage } from '../../../services/user/data/user-bonuses.data';
import { GamesLauncherService } from '../../../services/games/games-launcher.service';
import { LanguageService } from '../../../services/language/language.service';
import { ArabicService } from '../../../services/arabic.service';

import { CookieService } from 'ngx-unificator/services';
import { TabsComponent } from '../../../modules/tabs/tabs.component';
import {Title} from '@angular/platform-browser';
import { SafePipe } from '../../pipes/safe.pipe';
import { TranslatePipe } from '../../translation/translate.pipe';
import { TabItemComponent } from '../../../modules/tabs/tab-item.component';



const PARTNER_STAGS = ['171065'];

export interface BlogBonus {
  BonusTitle?: string;
  BonusImage?: string;
  BonusButtonRoute?: string;
  BonusButtonName?: string;
  BonusDesc?: string;
  slug?: string;
}
@Component({
  selector: 'app-bonus-preview',
  templateUrl: './bonus-preview.component.html',
  styleUrls: ['./bonus-preview.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TimerComponent,
    SafePipe,
    TranslatePipe,
    TabsComponent,
    TabItemComponent,
  ],
})
export class BonusPreviewComponent implements OnInit, AfterViewInit {
  @ViewChild('tab') tab: TabsComponent;
  @ViewChildren('amountItems')
  private _amountItems: QueryList<ElementRef>;

  @Input() bonus: CommonBonusInterface & BlogBonus & any;
  @Input() type: BONUS_TYPES = BONUS_TYPES.WELCOME;
  @Input() buttonTitle: string;

  public bonusTypes = BONUS_TYPES;
  public BonusStage = BonusStage;
  public bonusAmount = [];
  public isPartnerStag: boolean;

  /**
   * Cancel bonus event
   */
  @Output() onCancel: EventEmitter<CommonBonusInterface> = new EventEmitter<CommonBonusInterface>();

  /**
   * Activate bonus event
   */
  @Output() onActivate: EventEmitter<CommonBonusInterface> = new EventEmitter<CommonBonusInterface>();

  /**
   * Cancel lootbox event
   */
  @Output() onCancelLootbox: EventEmitter<number> = new EventEmitter<number>();

  /**
   * Open modal lootbox event
   */
  @Output() onOpenLootbox: EventEmitter<CommonBonusInterface> = new EventEmitter<CommonBonusInterface>();

  public pageTitle = this._title.getTitle();

  constructor(
    public user: UserService,
    public gamesLauncher: GamesLauncherService,
    public lang: LanguageService,
    public arabic: ArabicService,
    private _router: Router,
    private _modal: ModalService,
    private _cookie: CookieService,
    private _title: Title
  ) {
  }

  ngOnInit() {
    this.isPartnerStag = this._detectSpecialPartnerStag(PARTNER_STAGS);
    if (this.bonus?.AmountList?.AmountList) {
      this.resolveBonusAmountList();
    }
  }

  ngAfterViewInit() {
    if (this.tab) {
      const activeEl = this._amountItems.get(1);
      this.tab.selectTab(activeEl);
    }
  }

  public onBonusClick() {
    if (this.user.auth) {
      this._router.navigateByUrl('/deposit').then();
    } else {
      this.user.authUser().then();
    }
  }

  /**
   * Show detail modal
   * @param data
   */
  public async showDetailsModal(data) {
    const component = await import('../../../modal-v2/components/lazy/bonus-modal/bonus-modal.component');
    await this._modal.openLazy(component?.BonusModalComponent, {
      data,
      template: 'CLEAR'
    });
  }

  public onLockedTextClick(event) {
    const target: HTMLElement = event?.target;
    if (target?.tagName === 'A') {
      if (this.user?.currentCurrency?.amount <= 0) {
        this._router.navigateByUrl('/deposit').then();
      } else {
        this._router.navigateByUrl('/games').then();
      }
    }
  }

  private resolveBonusAmountList() {
    this.bonusAmount = Object.values(this.bonus?.AmountList?.AmountList);
  }

  private _detectSpecialPartnerStag(stags: string[]): boolean {
    if (this._cookie.check('ptag')) {
      return stags.some(stag => this._cookie.get('ptag').split('_')[0].includes(stag));
    }
  }

  /**
   * Reload details click
   * @param bonus
   */
  public async reloadDetailsClick(bonus: CommonBonusInterface & BlogBonus) {
    if (bonus.isLoyaltyBonus) {
      await this._router.navigateByUrl('loyalty-program');
    } else {
      await this.showDetailsModal(bonus);
    }
  }
}
