import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { environment } from 'src/environments/environment';
import { LanguageService } from './language/language.service';
import { SsApiService } from './api/ss-api.service';
import { first, map, switchMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { LocalstorageService } from './localstorage.service';
import { PlatformService } from './platform.service';

interface UbidexRequest {
  ua: string;
  ip: string;
  uid: string;
  cur: string;
  clickid: string;
  site: string;
  ln: string;
  affid: string;
  ts: any;
  event: string;
  s2s?: any;
  auth: string;
  amount?: string;
}

@Injectable({
  providedIn: 'root'
})
export class UbidexService {

  private readonly _trackingDomain = 'https://track-us.rwtrack.xyz';

  constructor(
    @Inject(WINDOW) private _window: Window,
    private _apiSs: SsApiService,
    private _storage: LocalstorageService,
    private _lang: LanguageService,
    private _platform: PlatformService
  ) {

  }

  /**
   * Sends a register event to Ubidex tracking when a user registers.
   * Gets the user's IP, prepares the tracking data,
   * sets the event to 'regfinished', and sends the tracking pixel.
   */
  public onRegister(user) {
    this._getUserIp$().pipe(
      first(),
      switchMap((ip: string) => {
        const data = {
          ...this._prepareData(),
          cur: user?.currency,
          uid: user?.id,
          event: 'regfinished',
          auth: '4rwvby',
          ip
        };
        return this._sendPixel$(data);
      })
    ).subscribe();
  }

  /**
   * Sends a login event to Ubidex tracking when a user logs in.
   * Gets the user's IP, prepares the tracking data,
   * sets the event to 'login', and sends the tracking pixel.
   */
  public onLogin(user) {
    this._getUserIp$().pipe(
      first(),
      switchMap((ip: string) => {
        const data = {
          ...this._prepareData(),
          cur: user?.currency,
          uid: user?.id,
          event: 'login',
          auth: 'dn27hy',
          ip
        };
        return this._sendPixel$(data);
      })
    ).subscribe();
  }

  /**
   * Sends a visit event to Ubidex tracking when a user visits.
   * Gets the user's IP, prepares the tracking data,
   * sets the event to 'visit', and sends the tracking pixel.
   */
  public onVisit(user = null) {
    if (this._platform.isBrowser) {
      if (user?.id) {
        if (this._window.sessionStorage.getItem('visited')) {
          return;
        }
        this._getUserIp$().pipe(
          first(),
          switchMap((ip: string) => {
            const data = {
              ...this._prepareData(),
              cur: user?.currency,
              uid: user?.id,
              event: 'visit',
              auth: 'dzy5b9',
              ip
            };
            return this._sendPixel$(data);
          })
        ).subscribe(() => {
          this._window.sessionStorage.setItem('visited', '1');
        });
      } else {
        if (this._window.sessionStorage.getItem('visited-non-auth')) {
          return;
        }
        const data = {
          ...this._prepareData(),
          cur: 'none',
          uid: 'none',
          event: 'visit',
          auth: 'dzy5b9',
          ip: '0.0.0.0'
        };

        this._sendPixel$(data).pipe(
          first()
        ).subscribe(() => {
          this._window.sessionStorage.setItem('visited-non-auth', '1');
        });
      }
    }
  }

  /**
   * Sends a deposit event to Ubidex tracking when a user makes a deposit.
   * Gets the user's IP, prepares the tracking data,
   * sets the event to 'deposit', and sends the tracking pixel.
   */
  public onDeposit(user, depData: any) {
    if (!depData.amount || !depData.currency) {
      return;
    }
    this._getUserIp$().pipe(
      first(),
      switchMap((ip: string) => {
        const data = {
          ...this._prepareData(),
          cur: depData?.currency,
          uid: user?.id,
          event: 'deposit',
          auth: 'dg19aj',
          amount: depData?.amount,
          ip
        };
        return this._sendPixel$(data);
      })
    ).subscribe();
  }

  private _prepareData() {
    return {
      ua: this._window.navigator.userAgent,
      site: this._window?.location?.origin?.includes('localhost') ? environment.app_host : this._window?.location?.origin,
      ts: new Date().getTime(),
      affid: this._storage?.get('id_id') || '',
      ln: this._lang.current,
      s2s: 1,
      clickid: null,
    };
  }

  private _getUserIp$() {
    return this._apiSs.userSessionData().pipe(
      map((e: any[]) => (e[0] || {})?.ip)
    );
  }

  private _sendPixel$(params: UbidexRequest) {
    const urlParams = new URLSearchParams(params as any);
    const url = `${this._trackingDomain}/pixel?${urlParams?.toString()}`;
    return from(fetch(url));
  }

}
