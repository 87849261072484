import { AbstractControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/user/user.service';
import { ToastMessageService } from '../../modules/toast-message/toast-message.service';
import { FormsErrorHandlerService } from '../../services/forms-error-handler.service';
import { FieldDescriptor, UserInfoService } from '../../services/user/user-info.service';
import { catchError, finalize, first, map, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CommonDataService } from '../../services/common-data.service';
import { EventEmitter } from '@angular/core';
import { ValidationPatterns } from '../validation-patterns';
import { CustomValidators } from '../custom-validators';
import { GoogleTagManagerService } from '../../services/google-tag-manager.service';
import { UserFieldDescriptor, userFields, UserFieldType } from '../user-fields.data';
import { DecoratorService } from '../decorator-service.service';
import {ModalRef} from '../../modal-v2/modal-ref';
import { UserPaymentSsV2Service } from '../../services/user/user-payment-ss-v2.service';

export enum ContextEdit {
  DEPOSIT = 'deposit',
  EDITION = 'edition',
}

export class UpdateProfileFormController {

  public fieldsData: {
    name: string,
    label: string,
    editable?: boolean,
    disabled?: boolean,
  }[] = [
    {name: 'nickname', label: 'labl.nickname', editable: true},
    {name: 'first_name', label: 'labl.first-name'},
    {name: 'last_name', label: 'labl.last-name'},
    {name: 'gender', label: 'labl.gender'},
    {name: 'date_of_birth', label: 'labl.date-b'},
    {name: 'country', label: 'labl.country'},
    {name: 'state', label: 'labl.state'},
    {name: 'city', label: 'labl.city'},
    {name: 'address', label: 'labl.addres'},
    {name: 'postal_code', label: 'labl.postal-code'},
    {name: 'email', label: 'labl.email'},
  ];

  /**
   * Access to global services
   */
  private _fb: UntypedFormBuilder = DecoratorService.injector.get(UntypedFormBuilder);
  private _user: UserService = DecoratorService.injector.get(UserService);
  private _userInfo: UserInfoService = DecoratorService.injector.get(UserInfoService);
  private _toastMessage: ToastMessageService = DecoratorService.injector.get(ToastMessageService);
  private _formErrors: FormsErrorHandlerService = DecoratorService.injector.get(FormsErrorHandlerService);
  private _gtm: GoogleTagManagerService = DecoratorService.injector.get(GoogleTagManagerService);
  private _payment: UserPaymentSsV2Service = DecoratorService.injector.get(UserPaymentSsV2Service);
  public data: CommonDataService = DecoratorService.injector.get(CommonDataService);

  /**
   * Update profile form
   */
  public form: UntypedFormGroup = this._fb.group({
    nickname: ['', Validators.required],
    first_name: ['', [Validators.required, Validators.pattern(ValidationPatterns.lettersAndSpecialSymbols)]],
    last_name: ['', [Validators.required, Validators.pattern(ValidationPatterns.lettersAndSpecialSymbols)]],
    date_of_birth: ['', [Validators.required, Validators.pattern(ValidationPatterns.dateOfBirth), CustomValidators.eighteenYearsOld]],
    gender: [null, Validators.required],
    country: [null, Validators.required],
    city: ['', [Validators.required, Validators.pattern(ValidationPatterns.onlyLettersAndSpacesAndHyphenBracesPoint), Validators.maxLength(255)]],
    address: ['', [Validators.required, Validators.pattern(ValidationPatterns.lettersAndNumbersWithSpacesAndSomeSymbols), Validators.maxLength(255)]],
    postal_code: ['', this._userInfo.isCA ? [Validators.required, Validators.pattern(ValidationPatterns.canadianPostalCode)] :  [Validators.required, Validators.pattern(ValidationPatterns.lettersAndNumbersWithSpacesAndSomeSymbols), Validators.maxLength(50)]],
    email: ['', Validators.required],
    state: ['', this.isShowState ? Validators.required : null]
  });

  public fields: Observable<UserFieldDescriptor[]&FieldDescriptor[]> = this._userInfo.missingAttributesFor(this.context).pipe(
    map(missedFields => missedFields.map((field: FieldDescriptor) => {
      const options = Boolean(field?.inclusion?.in?.length && field?.field !== 'gender') ? {
        options: of(field.inclusion.in).pipe(
          map((data) => data.map(op => [op, op] as [string, string]))
        )
      } : {};
      return userFields.has(field.field) ? {
        ...field,
        ...userFields.get(field.field),
        ...options
      } : {
        ...field,
        type: field.type === 'boolean' ? UserFieldType.CHECKBOX : UserFieldType.TEXT,
        validators: [Validators.required],
        label: field.field,
        ...options
      };
    })),
    map((fields) => {
      return this._prepareCustomFields(fields);
    }),
    tap(missedFields => {
      this.formForModal = this.buildForm(missedFields);
    }),
  );

  /**
   * Form for missed fields popup
   */
  public formForModal: UntypedFormGroup;

  /**
   * Is form loading
   */
  public loading: boolean;

  /**
   * Emits updating result
   */
  public updated$: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Current editable field by user
   */
  private _currentEditableField: string;

  public toggleInputs = false;

  public infoIcon: boolean;

  /**
   * Link to modal window
   */
  public modal: ModalRef;

  constructor(public context: string) {
    if (this.context !== ContextEdit.DEPOSIT) {
      this.context = ContextEdit.EDITION;
    }
  }

  public get isShowState() {
    return this._userInfo.isCA || this._userInfo.isAU;
  }

  buildForm(fields: UserFieldDescriptor[]&FieldDescriptor[]): UntypedFormGroup {
    const form: UntypedFormGroup = new UntypedFormGroup({});

    fields.forEach((field: UserFieldDescriptor&FieldDescriptor) => form.addControl(field.field, this._fb.control(null, field.validators)));

    return form;
  }

  /**
   * Returns form control by name
   *
   * @param name
   * @param form
   */
  field(name: string): AbstractControl {
    return this.form.get(name);
  }

  /**
   * Submit form handler
   */
  submit(form: UntypedFormGroup) {

    this._gtm.updateProfileModalSubmitClick('register_2nd_step', 'create-account-btn');
    this._formErrors.applyFormErrors(form, null, true);

    if (form.invalid) {
      return;
    }

    const formValue = { ...form.value };

    this.loading = true;
    this._userInfo.updatePlayerInfo(formValue, this.context).pipe(
      tap(() => {
        this._toastMessage.success('t.profile-updated');
      }),
      switchMap(() => this._user.getUserInfo()),
      tap(() => this.updated$.next(true)),
      catchError(error => {
        this._formErrors.applyFormErrors(form, error.error, true);
        this._toastMessage.error(this._formErrors.ssBackendErrorsToArray(error.error)[0] || 't.went-wrong');
        this.updated$.next(false);
        return of(error);
      }),
      finalize(() => {
        this._payment.hidePlayerFieldsInPaymentForm = true;
      }),
    ).subscribe(() => {
      this.addStateToFields();
      this.loading = false;
    });
  }

  /**
   * Show input for edit field
   */
  showInput(fieldName: string) {
    if (this._currentEditableField) {
      this.hideInput(this._currentEditableField);
    }
    this._currentEditableField = fieldName;
    this.field(fieldName).enable();
  }

  /**
   * Hide input for edit field
   */
  hideInput(fieldName: string) {
    if (fieldName === this._currentEditableField) {
      this._currentEditableField = null;
    }

    let value: string;
    if (this._user.info[fieldName]) {  // if user have filled data set it
      if (fieldName === 'date_of_birth') {
        value = this._user.info[fieldName].split('-').reverse().join('/');
      } else {
        value = this._user.info[fieldName];
      }
      this.field(fieldName).reset(value);
    } else if (this.field(fieldName).value) { // if user has already filled this field before in current form
      value = this.field(fieldName).value;
      this.field(fieldName).reset(value);
      this.field(fieldName).markAsDirty();
    } else {
      value = null;
      this.field(fieldName).reset(value);
    }

    this.field(fieldName).disable();
  }

  /**
   * Hide all inputs for fields
   */
  hideAllInputs() {
    Object.keys(this.form.controls).forEach(control => this.hideInput(control));
  }

  toggleAllInputs() {

    this.toggleInputs = !this.toggleInputs;

    if (this.toggleInputs) {
      Object.keys(this.form.controls).forEach(control => {
        if (this._user.info[control]) {
          this.field(control).disable();
        } else {
          this.field(control).enable();
        }
        });
    } else {
      this.hideAllInputs();
    }
  }

  addStateToFields() {
    Object.keys(this.form.controls).forEach(control => {
      this.fieldsData.forEach(field => {
        if (this._user.info[control] && field.name === control) {
          field.disabled = true;
        }
      });
    });
  }


  public handlePostalCodeChange(name: string) {
    if (name === 'postal_code' && this._userInfo.isCA) {
      const control = this.form.get(name);
      this.form.get(name).valueChanges.pipe(first()).subscribe(value => {
        control.patchValue(value.toUpperCase());
      });
    }
  }

  private _prepareCustomFields(fields) {
    if (this.modal?.options?.data?.fields) {
      return [...fields.filter((obj) => this.modal.options.data.fields.includes(obj.field))]
    }
    return fields;
  }
}
