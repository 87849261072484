import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { filter, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import {RegisterFormController, registrationStorageName} from '../../helpers/user/register-form-controller';
import {ModalService} from '../../modal-v2/modal.service';
import {BannersService} from '../../services/banners.service';
import {PlatformService} from '../../services/platform.service';
import {GoogleTagManagerService} from '../../services/google-tag-manager.service';
import {LocalHistoryService} from '../../services/local-history.service';
import {ToastMessageService} from '../toast-message/toast-message.service';
import {WindowService} from '../../services/window.service';
import {BreakpointsDetectorService} from '../../services/breakpoints-detector.service';
import {BodyService} from '../../services/body.service';
import {ModalsFromUrlList} from '../../modal-v2/modals-from-url-list';
import {BannerType} from '../../../../environments/environment';
import {isArray} from '../../helpers/utils';
import { SafePipe } from '../../shared/pipes/safe.pipe';
import { TranslatePipe } from '../../shared/translation/translate.pipe';
import { SocialAuthComponent } from '../../shared/components/social-auth/social-auth.component';
import { BtnComponent } from '../../shared/components/btn/btn.component';
import { AccordionDirective } from '../../shared/directives/accordion.directive';
import { CountrySelectorComponent } from '../../shared/components/country-selector/country-selector.component';
import { PasswordShowDirective } from '../../shared/directives/password-show.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClassLoadedDirective } from '../../shared/directives/class-loaded.directive';
import { SetImgAltDirective } from '../../shared/directives/set-img-alt.directive';
import { NgClass } from '@angular/common';
import { FormControlsModule } from '../form-controls/form-controls.module';

let timesClicked = 0;
export const requiredTooltipFields = ['email', 'nickname', 'password', 'tnc'];

@UntilDestroy()
@Component({
    selector: 'app-register-form',
    templateUrl: './register-form.component.html',
    styleUrls: ['./register-form.component.scss'],
    standalone: true,
    imports: [SetImgAltDirective, ClassLoadedDirective, FormsModule, ReactiveFormsModule, NgClass, PasswordShowDirective, CountrySelectorComponent, AccordionDirective, BtnComponent, FormControlsModule, SocialAuthComponent, TranslatePipe, SafePipe]
})
export class RegisterFormComponent implements OnInit, OnDestroy {

  @ViewChild('close') close: ElementRef;

  @Output()
  public close$: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Banner from CMS
   */
  public banner: any = {};

  /**
   * Controller for current register form
   */
  public controller: RegisterFormController = new RegisterFormController();

  /**
   * Current step (mobile only)
   */
  private _currentStep = 0;

  public isSubmitedBonusCode = false;

  public isOpened = false;

  private _beforeScrollPos = 0;

  constructor(
    private _modals: ModalService,
    private _banners: BannersService,
    private _router: Router,
    private _platform: PlatformService,
    private _gtm: GoogleTagManagerService,
    private _history: LocalHistoryService,
    private _toast: ToastMessageService,
    private _window: WindowService,
    public breakPoint: BreakpointsDetectorService,
    public body: BodyService,
  ) { }

  ngOnInit() {
    this._initBanner();
    this._handleError();
    this._handleTooltipError();
  }

  ngOnDestroy(): void {
    this.controller.toggleRegistrationDataToStorage();
  }

  onLoginClick() {
    this._history.addUrl('before-login', '/profile/deposit');
    this.close$.next(true);
    this._gtm.loginOnRegistrationClick('login_registration_form', 'registration_form');
    this._router.navigateByUrl('/?modal=' + ModalsFromUrlList.LOGIN);
  }

  /**
   * Send gtm data on field focus
   */
  onFocus(e, customName: string = null) {
    customName ? this._gtm.registerFormInputFocus(customName) : this._gtm.registerFormInputFocus(e.target.name);
  }

  async onClose() {
    this._history.removeUrl('before-login');

    timesClicked++;

    if (timesClicked >= 2) {
      this.close$.emit(true);
    } else {
      this.close$.emit(false);
      const component = await import('../../modal-v2/components/lazy/leave-register-modal/leave-register-modal.component');
      const modal = await this._modals.openLazy(component?.LeaveRegisterModalComponent, {
        template: 'CLEAR',
        disableOverlayClosing: true,
        data: this.banner,
      });

      modal.onResult().subscribe(e => {
        if (e) {this.close$.emit(true);}
      });
    }
  }

  /**
   *
   * @private
   */
  private _initBanner() {
    this._banners.item({
      type: BannerType,
      alias: 'sign-up'
    }).pipe(
      map(banners => isArray(banners) ? banners[0] : banners),
      tap(banner => this.banner = banner)
    ).subscribe();
  }

  /**
   * Handle registration error
   *
   * @private
   */
  private _handleError() {
    this.controller.error$.pipe(
      untilDestroyed(this),
      tap(error => {
        if (error.error.errors) {
          const errorKeys = Object.keys(error.error.errors);
          // const firstStepFields = Object.keys(this._steps[0]);
          // const secondStepFields = Object.keys(this._steps[2]);

          // if (errorKeys.some(key => secondStepFields.some(field => field === key))) { this.toStep(1); }
          // if (errorKeys.some(key => firstStepFields.some(field => field === key))) { this.toStep(0); }
        }
      })
    ).subscribe();
  }
  deleteDepositeBonus() {
    this.controller.input('bonus_code').setValue('');
    this.controller.input('bonus_code').enable();
    this.isSubmitedBonusCode = false;
  }

  onBonuscodeFormSubmit() {
    this.isSubmitedBonusCode = true;
    const bonusControl = this.controller.input('bonus_code');
    bonusControl.disable();
    this._toast.success('t.bonus-code-added');
  }

  focusCode(codeInput: HTMLInputElement) {
    if (!this._platform.isBrowser) {
      return;
    }
    this.isOpened = !this.isOpened;
    if (this.isOpened) {
      if (this.breakPoint.isMobile) {
        const y = codeInput.getBoundingClientRect().top + this._window.nativeWindow.scrollY - 50;
        this._beforeScrollPos = this._window.nativeWindow.scrollY;
        this._window.nativeWindow.scrollTo({
          top: y,
          behavior: 'smooth',
        });
        codeInput.focus();
      } else {
        codeInput.focus();
      }
    } else {
      this._window.nativeWindow.scrollTo({
        top: this._beforeScrollPos,
        behavior: 'smooth',
      });
    }
  }


  private _handleTooltipError() {
    requiredTooltipFields.forEach((e) => {
      e === 'tnc' ? this.controller.form.get('terms_acceptance')['fieldName'] = e
        : this.controller.form.get(e)['fieldName'] = e;
    });
  }
}
