import { OnInit, Component, Input, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl, ReactiveFormsModule } from '@angular/forms';
import {PaymentsMethodField} from "../../../../../../../core/vendor/ss-payments-v2/ss-payment-types";
import {Form} from "payments-lib-types";
import { FormInputComponent } from '../../../../../../../core/modules/form-controls/form-input/form-input.component';
import { SelectComponent } from '../../../../../../../core/modules/select/select.component';
import { SelectHeaderComponent } from '../../../../../../../core/modules/select/select-header/select-header.component';
import {
  SelectDropdownComponent
} from '../../../../../../../core/modules/select/select-dropdown/select-dropdown.component';
import { SelectOptionComponent } from '../../../../../../../core/modules/select/select-option/select-option.component';

/**
 * Object that holds information about dependent select fields. Every time value in first select changes, second select updates accordingly.
 */
export interface CascadeSelectField {
  /**
   * "cascade_select"
   */
  type: string;
  /**
   * Key for primary select
   */
  primary: string;
  /**
   * Key for secondary select
   */
  secondary: string;
  /**
   * Possible values to display for both select fields.
   */
  values: CascadeSelectValues[];
}

/**
 * Object that holds values for cascade select fields
 */
export interface CascadeSelectValues {
  /**
   * Value to display for user for primary select field
   */
  name: string;
  /**
   * Value to send to server for primary select fiel
   */
  value: string;
  /**
   * Possible values for secondary select for given primary's select. Includes both name to show to user and value to send to server
   */
  list: [string, string][];
}

@Component({
  selector: 'app-cascade-select',
  templateUrl: './cascade-select.component.html',
  styleUrls: ['./cascade-select.component.scss'],
  standalone: true,
  imports: [
    FormInputComponent,
    SelectComponent,
    SelectHeaderComponent,
    SelectDropdownComponent,
    SelectOptionComponent,
    ReactiveFormsModule,
  ],
})
export class CascadeSelectComponent implements OnInit, OnDestroy {

  @Input() field: PaymentsMethodField;

  /**
   * Primary control
   */
  @Input() primary: AbstractControl;

  /**
   * Secondary control
   */
  @Input() secondary: FormControl;

  /**
   * Secondary select options for active primary option
   */
  public secondaryOptions: Form.Option[];

  constructor() { }

  ngOnInit() {
    this.secondary.disable();
  }

  /**
   * Update secondary select options on primary change
   */
  public updateSecondaryOptions(value: string) {
    this.secondary.setValue(null);
    this.secondaryOptions = this.field.secondaryOptionsMapByMainValue[value] || [];
    if (this.secondaryOptions?.length) {
      this.secondary?.enable();
    } else {
      this.secondary?.disable();
    }
  }


  ngOnDestroy() {

  }
}
