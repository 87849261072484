<div class="login-container">
  @if (user.isUserAlreadyRegistered) {
    <button
      class="btn btn--filled-primary"
      routerLink="./"
      [queryParams]="{modal: 'login'}"
      (click)="onLoginClick()">
      {{ 'btn.login' | translate }}
    </button>
  } @else {
    @if (!breakpoints.isMobile) {
      <button
        class="btn btn--simple-primary"
        routerLink="./"
        (click)="onLoginClick()"
        [queryParams]="{modal: 'login'}">
        {{'btn.login' | translate}}
      </button>
    }
    <button
      class="btn btn--filled-primary"
      routerLink="./"
      (click)="onRegisterClick()"
      [queryParams]="{modal: 'registration'}">
      {{'btn.signup' | translate}}
    </button>
  }

</div>

