import {Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import { UserPhoneService } from '../../../services/user/user-phone.service';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgModel, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { CommonDataService, CountryList$ } from '../../../services/common-data.service';
import { EnvironmentService } from '../../../services/environment.service';
import { first, tap } from 'rxjs/operators';
import {PHONE_MASKS} from '../../../helpers/phone-masks';
import { TranslatePipe } from '../../translation/translate.pipe';
import { NgxMaskDirective } from 'ngx-mask';
import { NgClass, AsyncPipe } from '@angular/common';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';
import { SetImgAltDirective } from '../../directives/set-img-alt.directive';

@Component({
    selector: 'app-phone-form',
    templateUrl: './phone-form.component.html',
    styleUrls: ['./phone-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PhoneFormComponent),
            multi: true
        }
    ],
    standalone: true,
    imports: [SetImgAltDirective, ClickOutsideDirective, NgClass, FormsModule, ReactiveFormsModule, NgxMaskDirective, AsyncPipe, TranslatePipe]
})
export class PhoneFormComponent implements OnInit, ControlValueAccessor {
  /**
   * Input model
   */
  @ViewChild('inputModel') inputModel: NgModel;

  /**
   * Current flag country
   */
  public currentCountry: string;

  /**
   * Search control
   */
  public search: FormControl = new FormControl('');

  /**
   * Is country list open
   */
  private _open: boolean;

  /**
   * Country list after filter
   */
  public filteredCountryList$: ReplaySubject<any[]> = CountryList$;

  onChange: any = () => {
  }

  onTouch: any = () => {
  }

  set value(val) {
    const resolvedValue = this.userPhone.prefix + val;
    this.onChange(resolvedValue);
    this.onTouch(resolvedValue);
  }

  get isOpen(): boolean {
    return this._open;
  }

  constructor(
    public userPhone: UserPhoneService,
    public env: EnvironmentService,
    private _data: CommonDataService
  ) {
    this.env.env$.pipe(
      first(),
      tap(({data}) => {
        this.onSelect(data.country);
      })
    ).subscribe();
  }

  ngOnInit() {
    this._onHandleSearch();
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  /**
   * Open countries list
   */
  onOpen() {
    this._open = true;
  }

  /**
   * Close countries list
   */
  onClose() {
    this._open = false;
  }

  onSelect(country) {
    this.onClose();
    this.currentCountry = country.short;
    this.userPhone.Prefix = `+${ country.callingCode }`;
    this.userPhone.Mask = PHONE_MASKS.find(item => item.country.toLowerCase() === country.short);
    this.inputModel?.reset();
  }

  /**
   * Handle search form and filter countries
   *
   * @private
   */
  private _onHandleSearch() {
    this.search.valueChanges.pipe(
      tap(value => this.filteredCountryList$.next(
        this._data.countryList.filter(country => country.name.toLowerCase().includes(value.toLowerCase()))
      ))
    ).subscribe();
  }

}
