import { Injectable } from '@angular/core';
import {ModalService} from '../modal-v2/modal.service';
import {UserService} from './user/user.service';
import {CmsApiService} from './api/cms-api.service';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {GroupsService} from './groups.service';
import { CookieService } from 'ngx-unificator/services';
import {combineLatest, Observable, of} from 'rxjs';
import {UserTransactionsService} from './user/user-transactions.service';

const ONE_WITHDRAWAL_GROUP: string = 'ID591';
const USER_USE_MOTIVATE_WITHDRAWAL_GROUP: string = 'ID590';
const NEW_USERS_GROUP: string = 'ID596';
const LAST_TIME_WITHDRAWAL_DISPLAY: string = '--last-time-withdrawal-display';
@Injectable({
  providedIn: 'root'
})
export class MotivateWithdrawalService {

  /**
   * Max available amount in EUR
   * @private
   */
  private _maxAvailableAmountInEUR: number = 1500;

  /**
   * Default currency
   * @private
   */
  private _defaultCurrency: string = 'EUR';

  /**
   * True if motivate withdrawal available
   */
  public isMotivateWithdrawalAvailable: boolean;

  /**
   * Fifteen in milliseconds
   * @private
   */
  private _fifteenMinutesInMilliseconds: number = 60000 * 15;

  constructor(
    private _modal: ModalService,
    private _user: UserService,
    private _api: CmsApiService,
    private _groups: GroupsService,
    private _cookie: CookieService,
    private _transactions: UserTransactionsService
  ) {
    this._setIsMotivateWithdrawalAvailable();
  }

  public initMotivateWithdrawal() {
    // functionality disabled

    // if (this._user.auth && this._groups.isExistGroup(NEW_USERS_GROUP) && !this._groups.isExistGroup(ONE_WITHDRAWAL_GROUP)) {
    //   combineLatest([this._getAmountBetsInEUR(), this._getFirstDepositInEUR(), this._getAvailableCashoutInEUR()]).pipe(
    //     filter(([bets, firstDeposit, balance]) => !!bets && !!firstDeposit && !!balance),
    //     filter(([bets, firstDeposit, balance]) => (balance / firstDeposit >= 1.5 && balance < this._maxAvailableAmountInEUR) && bets >= 3 * firstDeposit),
    //     tap(async () => {
    //       await this.showModalWithdrawal();
    //       this.isMotivateWithdrawalAvailable = true;
    //       if (!this._groups.isExistGroup(USER_USE_MOTIVATE_WITHDRAWAL_GROUP)) {
    //         this._groups.addToGroup(USER_USE_MOTIVATE_WITHDRAWAL_GROUP).subscribe();
    //       }
    //     })
    //   ).subscribe();
    // }
  }

  /**
   * Get first deposit
   * @private
   */
  private _getFirstDepositInEUR(): Observable<number> {
    return this._transactions.transactionList().pipe(
      map((data) => data.sort((prev, next) => new Date(prev?.finished_at) < new Date(next?.finished_at))),
      map(data => data?.find(item => item?.success)),
      filter(data => !!data),
      switchMap(data => combineLatest([of(data?.amount), this._api.getExchangeRate({from: data?.currency, to: this._defaultCurrency})])),
      map(([amount, data]) => {
        const rate = data?.data?.rate;
        return [amount, rate];
      }),
      filter(([amount, rate]) => amount && rate),
      map(([amount, rate]) => Number((amount * rate).toFixed(1))),
    );
  }

  /**
   * Get sum user bets
   * @private
   */
  private _getAmountBetsInEUR(): Observable<number> {
    return this._transactions.betList().pipe(
      switchMap(data => combineLatest( [of(data), combineLatest(data.map(bet => this._api.getExchangeRate({from: bet?.currency, to: this._defaultCurrency})))])),
      map((data: any) => [data[0].map(d => d.amount), data[1].map((r: any) => Number(r?.data?.rate))]),
      map(data => [...data[0].map((amount, i) => Number((amount * data[1][i]).toFixed(1)))].reduce((acc, curr) => acc + curr, 0)),
    );
  }

  /**
   * Get available cashout
   * @private
   */
  private _getAvailableCashoutInEUR(): Observable<number> {
    return this._user.auth$.pipe(
      filter(data => !!data),
      switchMap(() => this._user.currentCurrencyCompatibility$),
      switchMap(data => combineLatest([of(data?.cashout), this._api.getExchangeRate({from: data?.currency, to: this._defaultCurrency })])),
      map(([amount, dataRate]) => Number((amount * dataRate?.data?.rate).toFixed(1))),
    );
  }

  /**
   * Set last time withdrawal display in cookie
   * @private
   */
  private _setLastTimeWithdrawalDisplay() {
    this._cookie.set(LAST_TIME_WITHDRAWAL_DISPLAY, String(Date.now()), 999, '/');
  }

  /**
   * Return true if the popup was displayed more than 15 minutes ago
   * @private
   */
  private _isMoreFifteenMinutesLastShownModal(): boolean {
    const lastShownDate = this._cookie.get(LAST_TIME_WITHDRAWAL_DISPLAY);
    return !Boolean(lastShownDate) || Date.now() - Number(lastShownDate) > this._fifteenMinutesInMilliseconds;
  }

  /**
   * Show withdrawal modal
   * @private
   */
  public async showModalWithdrawal() {
    if (this._isMoreFifteenMinutesLastShownModal()) {
      this._setLastTimeWithdrawalDisplay();

      const component = await import('../modal-v2/components/lazy/withdrawal/withdrawal.component');
      const modal = await this._modal.openLazy(component?.WithdrawalComponent, {
        template: 'CLEAR'
      });
    }
  }

  /**
   * set isMotivateWithdrawalAvailable for change templates
   * @private
   */
  private _setIsMotivateWithdrawalAvailable() {
    // functionality disabled

    // if (this.isMotivateWithdrawalAvailable === undefined) {
    //   this.isMotivateWithdrawalAvailable = this._groups.isExistGroup(USER_USE_MOTIVATE_WITHDRAWAL_GROUP) && !this._groups.isExistGroup(ONE_WITHDRAWAL_GROUP);
    // }
  }
}
