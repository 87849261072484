import { Component, OnInit } from '@angular/core';
import { LoginFormController } from '../../../../helpers/user/login-form-controller';
import { SocialAuthLink } from '../../../../../../environments/environment';
import { GoogleTagManagerService } from '../../../../services/google-tag-manager.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { BreakpointsDetectorService } from 'src/app/core/services/breakpoints-detector.service';
import { TranslatePipe } from '../../../translation/translate.pipe';
import { RouterLinkDirective } from '../../../directives/router-link.directive';
import { RouterLink } from '@angular/router';


@Component({
    selector: 'app-header-login-form',
    templateUrl: './header-login-form.component.html',
    styleUrls: ['./header-login-form.component.scss'],
    standalone: true,
    imports: [RouterLink, RouterLinkDirective, TranslatePipe]
})
export class HeaderLoginFormComponent implements OnInit {

  /**
   * Controller for current login form
   */
  public controller: LoginFormController = new LoginFormController();

  constructor(
    private _gtm: GoogleTagManagerService,
    public user: UserService,
    public breakpoints: BreakpointsDetectorService,
  ) { }

  get Link() { return SocialAuthLink; }

  ngOnInit() {
  }

  onLoginClick() {
    this._gtm.signInHeaderClick('login_header', 'header');
  }

  onRegisterClick() {
    this._gtm.registerHeaderClick('register_header', 'header_menu');
  }
}
