<aside
  class="aside"
  #nav
  ClickOutside
  (clickOutside)="sidebar.close()"
  [ngClass]="{
    open: sidebar.isOpen,
  }"
>
  <!--SIDEBAR HEADER-->
  <div class="aside__header">
    <i class="close icon-close" (click)="sidebar.close()"></i>
    <a *appShellRenderSSR [href]="seo.originalLocationUrl + '/' + lang.current">
      <img class="logo" src="/assets/img/logo.png" alt="" />
    </a>
    <a *appShellNoSSR [href]="seo.originalLocationUrl + '/' + lang.current" [routerLink]="['/', lang.current]" (click)="sidebar.close()">
      <img class="logo" src="/assets/img/logo.png" alt="" />
    </a>
  </div>
  <!--END SIDEBAR HEADER-->

  <!-- BALANCE -->
  @if (user.auth) {
    <div class="side-link">
      <img class="side-link__icon balance-image" [src]="'/assets/img/new-categories/balance.png'" alt="" />
      <div class="balance-text">
        <span>{{ 't.balance' | translate }}:</span>
        <span class="side-link__label balance">
          {{ user.currentCurrency?.symbol || user.currentCurrency?.currency }}
          {{ user.currentCurrency?.amount | currencyFormat: user.currentCurrency?.currency }}
        </span>
      </div>
    </div>
  }
  <!-- END BALANCE -->

  <div class="aside__content">
    @if (user.auth) {
      <div class="account-buttons">
        <button
          class="btn btn--primary big btn--deposit"
          [routerLink]="motivateWithdrawal.isMotivateWithdrawalAvailable ? '/profile/cashout' : '/profile/deposit'"
          (click)="onDepositClick(!motivateWithdrawal.isMotivateWithdrawalAvailable)"
        >
          {{ (motivateWithdrawal.isMotivateWithdrawalAvailable ? 'btn.cashout' : 'btn.deposit') | translate }}
        </button>
      </div>
    }

    <!--ACCOUNT BUTTONS-->
    @if (!user.auth) {
      <div class="account-buttons">
        @if (user.isUserAlreadyRegistered) {
          <button class="btn btn--primary big" routerLink="./" [queryParams]="{ modal: 'login' }" (click)="onLoginClick()">
            {{ 'btn.login' | translate }}
          </button>
        } @else {
          <button class="btn btn--primary big" routerLink="./" [queryParams]="{ modal: 'registration' }" (click)="onRegisterClick()">
            {{ 'btn.signup' | translate }}
          </button>
          <button class="btn btn--simple-primary big" routerLink="./" [queryParams]="{ modal: 'login' }" (click)="onLoginClick()">
            {{ 'btn.login' | translate }}
          </button>
        }
      </div>
    }
    <!--END ACCOUNT BUTTONS-->

    <!--PRIMARY MENU-->
    <div class="primary-links">
      @if (qatarTournament$ | async; as qatarTournament) {
        <a class="side-link" (click)="toTournament(['/', lang.current, 'tournaments', qatarTournament.slug]); this.sidebar.close()">
          <img class="side-link__icon" src="/assets/img/qatar-ball.png" />
          <span class="side-link__label">{{ qatarTournament?.Title }}</span>
        </a>
      }

      @if (summer.linkMenuEnabled && !ar.isAr) {
        <a
          class="side-link side-link--promo-menu valentine"
          [routerLink]="['/', lang.current, 'promo', 'summer']"
          (click)="this.sidebar.close()"
        >
          <img class="side-link__icon promo valentine" src="/assets/img/promo/summer/icon.png" />
          <span class="side-link__label">{{ 'link.summer' | translate }}</span>
        </a>
      }

      @if (user.auth) {
        <a class="side-link" [routerLink]="['/', lang.current, 'profile', 'account']" (click)="onAccountClick()">
          <img class="side-link__icon" [src]="'/assets/img/new-categories/account.png'" />
          <span class="side-link__label">{{ 'link.account' | translate }}</span>
        </a>
      }

      @if (user.auth) {
        <a class="side-link" (click)="onMissionsClick()">
          <img class="side-link__icon" [src]="'/assets/img/missions/logo.svg'" />
          <span class="side-link__label">{{ 'link.missions' | translate }}</span>
        </a>
      }

      @if (!user.auth) {
        <a class="side-link" (click)="onAccountClick()">
          <img class="side-link__icon" [src]="'/assets/img/new-categories/account.png'" />
          <span class="side-link__label">{{ 'link.account' | translate }}</span>
        </a>
      }

      <a class="side-link" [routerLink]="['/', lang.current, 'bonuses']" (click)="onBonusesClick()">
        <img class="side-link__icon" [src]="'/assets/img/new-categories/offers.png'" />
        <span class="side-link__label">
          {{ 'link.bonuses' | translate }}
        </span>
      </a>

      <a class="side-link" [routerLink]="['/', lang.current, 'loyalty-program']" (click)="sidebar.close()">
        <img class="side-link__icon" [src]="'/assets/img/new-categories/loyalty.png'" />
        <span class="side-link__label">{{ 'link.loyalty-program' | translate }}</span>
      </a>

      <a class="side-link" [routerLink]="['/', lang.current, 'games']" (click)="sidebar.close(); sidebar.setisOpenedCasinoTodayInCookie()">
        <img class="side-link__icon" [src]="'/assets/img/new-categories/games.png'" />
        <span class="side-link__label">
          {{ 'link.casino' | translate }}
          @if (!sidebar.isOpenedCasinoToday && badge?.gameBadge) {
            <img class="side-link__badge" [src]="ar?.isAr ? '/assets/img/categories/notif-ar.png' : '/assets/img/categories/notif.png'" />
          }
        </span>
      </a>

      @if (zendesk.available) {
        <a class="side-link" (click)="zendeskLiveChatClick()">
          <img class="side-link__icon" [src]="'/assets/img/new-categories/help-chat.png'" />
          <span class="side-link__label">
            {{ 'link.help' | translate }}
          </span>
        </a>
      }

      <a class="side-link" (click)="installAppClick()" [routerLink]="['/', lang.current, 'mobile-app']">
        <img class="side-link__icon" [src]="'/assets/img/new-categories/pwa.png'" />
        <span class="side-link__label">{{ 'link.instal-app' | translate }}</span>
      </a>

      <div>
        <span #moreMenu Accordion class="side-link settings" (click)="onClickSubmenu(moreMenu)" [closeAfterChangeLang]="true">
          <img class="side-link__icon" [src]="'/assets/img/new-categories/settings.png'" />
          <span class="side-link__label">
            {{ 'link.settings' | translate }}
          </span>
          <span class="side-link__chevron"></span>
        </span>

        <div class="submenu settings">
          @if (user.auth) {
            <a class="side-link small-link" [routerLink]="['/', lang.current, 'profile', 'info']" (click)="sidebar.close()">
              <i class="icon-settings"></i>
              <span class="side-link__label">{{ 'link.my-acc' | translate }}</span>
            </a>
            <a class="side-link small-link" [routerLink]="['/', lang.current, 'profile', 'documents']" (click)="sidebar.close()">
              <i class="icon-settings"></i>
              <span class="side-link__label">{{ 'link.doc' | translate }}</span>
            </a>
          }

          <a class="side-link small-link" [routerLink]="['/', lang.current, 'blog']" (click)="sidebar.close()">
            <span class="side-link__label">{{ 'link.blog' | translate }}</span>
          </a>

          <a class="side-link small-link" [routerLink]="['/', lang.current, 'tournaments']" (click)="sidebar.close()">
            <span class="side-link__label">
              {{ 'link.tournaments' | translate }}
            </span>
          </a>

          @if (showLotteryLink) {
            <a class="side-link small-link" [routerLink]="['/', lang.current, 'lottery']" (click)="sidebar.close()">
              <span class="side-link__label">{{ 't.lottery' | translate }}</span>
            </a>
          }

          <a class="side-link small-link" [routerLink]="['/', lang.current, 'banking']" (click)="sidebar.close()">
            <span class="side-link__label">{{ 'link.banking' | translate }}</span>
          </a>

          @if (user.auth) {
            <span class="side-link small-link logout" (click)="logout()">
              <span class="side-link__label">{{ 'link.logout' | translate }}</span>
            </span>
          }

          <hr class="separator" />

          <a class="side-link small-link" [routerLink]="['/', lang.current, 'faq']" (click)="sidebar.close()">
            <span class="side-link__label">{{ 'link.faq' | translate }}</span>
          </a>

          <a class="side-link small-link" [routerLink]="['/', lang.current, 'about']" (click)="sidebar.close()">
            <span class="side-link__label">{{ 'link.about' | translate }}</span>
          </a>


          <a class="side-link small-link" [routerLink]="['/', lang.current, 'terms-and-conditions']" (click)="sidebar.close()">
            <span class="side-link__label">{{ 'link.t&c' | translate }}</span>
          </a>

          <a class="side-link small-link" [routerLink]="['/', lang.current, 'bonus-terms']" (click)="sidebar.close()">
            <span class="side-link__label">{{ 'link.bonus-terms' | translate }}</span>
          </a>

          <a class="side-link small-link" [routerLink]="['/', lang.current, 'privacy-policy']" (click)="sidebar.close()">
            <span class="side-link__label">{{ 'link.pp' | translate }}</span>
          </a>

          <a class="side-link small-link" [routerLink]="['/', lang.current, 'cookie-policy']" (click)="sidebar.close()">
            <span class="side-link__label">{{ 'link.cookie-policy' | translate }}</span>
          </a>

          <a class="side-link small-link" [routerLink]="['/', lang.current, 'responsible-gaming']" (click)="sidebar.close()">
            <span class="side-link__label">{{ 'link.res-game' | translate }}</span>
          </a>

          <a class="side-link small-link" target="_blank" href="https://www.samuraipartners.com" (click)="sidebar.close()">
            <span class="side-link__label">{{ 'link.affiliate' | translate }}</span>
          </a>

          @if (ask.linkMenuEnabled) {
            <a class="side-link small-link" [routerLink]="['/', lang.current, 'promo', 'askgamblers']" (click)="sidebar.close()">
              <span class="side-link__label">{{ 'link.askgamblers' | translate }}</span>
            </a>
          }
        </div>

        <div class="lang" #langRef (click)="onClickSubmenu(langRef)">
          <div #accordion="accordionDirective" Accordion class="lang__header">
            <span class="lang-item">
              <img class="lang-item__icon" [src]="'/assets/svg/lang/' + lang.current + '.svg'" />
              <span class="lang-item__label">{{ lang.current }}</span>
            </span>

            <span class="side-link__chevron"></span>
          </div>

          <div class="lang__list">
            @for (lang of env.env.languageList; track lang) {
              <span class="lang-item" (click)="changeLanguage(lang.short)">
                <img class="lang-item__icon" [src]="'/assets/svg/lang/' + lang.short + '.svg'" />
                <span class="lang-item__label">{{ lang.short }}</span>
              </span>
            }
          </div>
        </div>
      </div>
    </div>
    <!--END PRIMARY MENU-->
  </div>
</aside>
