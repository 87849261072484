import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseTimerComponent} from '../../../helpers/base-timer.component';
import {TimeService} from '../../../services/time.service';


import { TranslatePipe } from '../../translation/translate.pipe';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
  standalone: true,
  imports: [
    TranslatePipe
],
})
export class TimerComponent extends BaseTimerComponent implements OnInit, OnChanges {

  @Input() tournament: any;

  public progress: string = '';

  constructor(
    private _time: TimeService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.tournament?.firstChange) {
      this._resolveProgress(changes?.tournament?.currentValue);
    }
  }

  ngOnInit(): void {
    this.resolveTimer();
  }

  private _resolveProgress(tournament: any) {
    const startAt = this._getStartAt(tournament);
    const endAt = this._getEndAt(tournament);
    const now = new Date().getTime();
    const percentage = (now - startAt) / (endAt - startAt) * 100;
    this.progress = Math.round(percentage) + '%';
  }

  private _getStartAt(item: any) {
    if (item?.activeMultiTournament?.start_at) { return new Date(item?.activeMultiTournament?.start_at).getTime(); }
    else if (item?.publishAt?.date) { return new Date(item?.publishAt?.date).getTime(); }
    return new Date(item?.publishAt).getTime();
  }

  private _getEndAt(item: any) {
    if (item?.activeMultiTournament?.end_at) { return new Date(item?.activeMultiTournament?.end_at).getTime(); }
    else if (item?.unpublishAt?.date) { return new Date(item?.unpublishAt?.date).getTime(); }
    return new Date(item?.unpublishAt).getTime();
  }
}
