import { Injectable } from '@angular/core';
import {ScriptLoaderService} from '../script-loader.service';
import {catchError, tap, filter, map, shareReplay, switchMap, delay, takeWhile, endWith} from 'rxjs/operators';
import {fromEvent, of, ReplaySubject} from 'rxjs';
import {UserService} from '../user/user.service';
import {LanguageService} from '../language/language.service';
import {PlatformService} from '../platform.service';
import { CookieService } from 'ngx-unificator/services';
import {CommonDataService} from '../common-data.service';
import {EnvironmentService} from '../environment.service';
import {GroupsService} from '../groups.service';
import {UserTransactionsService} from '../user/user-transactions.service';
import {StaticContentService} from '../static-content.service';
import {
  DEFAULT_DEPARTMENTS_LIST, FIVE_DOLLARS_DEPARTMENT, FIVE_DOLLARS_STAGS,
  IZendeskUserData, ONLINE_CHAT_COUNTRIES,
  VIP_CHAT_EXPIRE_AT_COOKIE, VIP_DEPARTMENT,
  ZENDESK_SCRIPT_URL,
} from './zendesk-chat-data';
import {environment} from "../../../../environments/environment";

declare var zE;

@Injectable({
  providedIn: 'root'
})

export class ZendeskChatService {

  /**
   * Returns true if zendesk chat available
   */
  private _available: boolean;

  /**
   * Emits true if zendesk chat available
   */
  private _available$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  /**
   * Is vip chat set up now
   */
  public isVipChatNow: boolean;

  /**
   * Returns true if chat open
   * @private
   */
  private _isOpen: boolean;

  /**
   * Timeout to restore default chat (remove vip)
   */
  private _timeoutToRemoveVipChat: any;

  /**
   * Time to restore default chat after closing vip chat
   */
  private readonly _timeToRestoreDefaultChat = 20 * 60 * 1000;

  constructor(
    private _scriptLoader: ScriptLoaderService,
    private _user: UserService,
    private _lang: LanguageService,
    private _platform: PlatformService,
    private _cookie: CookieService,
    private _common: CommonDataService,
    private _env: EnvironmentService,
    private _transactions: UserTransactionsService,
    private _static: StaticContentService,
  ) {
  }

  /**
   * Success from outside
   */
  get available() {
    return this._available;
  }

  get available$() {
    return this._available$;
  }

  get isOpen() {
    return this._isOpen;
  }

  /**
   * Get widget api key
   * @private
   */
  private _getWidgetApiKey$() {
    return this._static.item({slug: 'zendesk-api-key'}).pipe(
      filter(data => !!data && data[0]),
      map(data => data[0].Content && data[0].Content.trim())
    );
  }

  /**
   * Init zendesk chat
   */
  public initChat(forVip: boolean = false) {
    if (this._platform.isBrowser) {
      this._user.auth$.pipe(
        switchMap(() => this._getWidgetApiKey$()),
        filter(key => !!key),
        tap(() => {
          const zeScript = document.querySelector('#ze-snippet');
          if (zeScript) {
            zeScript.remove();
          }
          this._available = false;
          this._available$.next(false);
        }),
        delay(0),
        switchMap(key => this._scriptLoader.load(`${ZENDESK_SCRIPT_URL}?key=${key}&timestamp=${Date.now()}`, undefined, {id: 'ze-snippet'})),
        filter(() => Boolean(zE)),
        tap(() => {
          this._available = true;
          this._available$.next(true);
          this._setInitFunctionalData(forVip);
          zE('messenger', 'close');
        }),
        catchError(error => {
          this._available = false;
          this._available$.next(false);
          return of(error);
        })
      ).subscribe();
    }
  }

  /**
   * Set init data for functional chat
   * @private
   */
  private _setInitFunctionalData(isVip: boolean = false) {
    this.hide();

    if (this._user.auth) {
      this.setUserData();
    }
    this._onClose();
    this._onChangeLanguage();
    // this._closeByDocumentClick();
    this._onMessage();
    this._addTags([this._lang.current ? this._lang.current : '',
      this._cookie.get('id_id') ? this._cookie.get('id_id') : '']);
  }

  /**
   * Toggle chat
   * @open
   */
  public toggle() {
    this.isOpen ? this.hide() : this.open();
  }

  /**
   * Hide chat
   */
  public hide() {
    zE('messenger', 'close');
  }

  /**
   * Open chat
   */
  public open() {
    zE('messenger', 'open');
  }

  /**
   * Handler on close widget
   * @private
   */
  private _onClose() {
    zE('messenger:on', 'close', () => {
      this._isOpen = false;
    });
  }

  /**
   * Set locale for chat
   * @private
   * @locale
   */
  private _setLocale(locale) {
    if (this.available) {
      zE('messenger:set', 'locale', locale);
    }
  }

  /**
   * Add tags
   * @private
   * @tags
   */
  private _addTags(tags: string[]) {
    zE('messenger:set', 'conversationTags', tags);
  }

  /**
   * Change locale for chat
   * @private
   */
  private _onChangeLanguage() {
    this._lang.langChange$.pipe(
      tap(locale => this._setLocale(locale))
    ).subscribe();
  }

  /**
   * Close by document click
   * @private
   */
  private _closeByDocumentClick() {
    if (this._platform.isBrowser) {
      fromEvent(document, 'click').pipe(
        filter(() => this._isOpen),
        tap(() => this.hide())
      ).subscribe();
    }
  }

  /**
   * Open chat if message coming
   * @private
   */
  private _onMessage() {
    if (this.available && zE) {
      zE('messenger:on', 'unreadMessages', countMessage => {
        if (!this._isOpen && countMessage > 1) {
          this.open();
        }
      });
    }
  }


  /**
   * Restore default chat in passed ms
   */
  private _restoreDefaultChatIn(ms: number) {
    this._timeoutToRemoveVipChat = setTimeout(() => {
      this.initChat(false);
    }, ms);
  }


  /**
   * Use for suppress in webwidget config
   * @private
   */
  private _isUseOfflineForm() {
    return !ONLINE_CHAT_COUNTRIES.includes(this._env.env.country.short);
  }

  /**
   * Set user data (name, email)
   * @param data
   * @private
   */
  public setUserData() {
    const userObj = {
      user_id: `${this._user.info.id}`,
      first_name: this._user.info.first_name,
      last_name: this._user.info.last_name,
      date_of_birth: this._user.info.date_of_birth,
      email: this._user.info.email
    };
    if (Object.values(userObj).every((e) => !!e)) {
      zE('messenger', 'loginUser', (callback) => {
        fetch(`${environment.zendesk_host}/auth`, {method: 'POST', body: JSON.stringify(userObj)}).then((res) => {
          res.json().then((jwt) => {
            callback(jwt.token);
          });
        });
      });
    }
  }

}

