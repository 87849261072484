import { CookieService } from 'ngx-unificator/services';
import {catchError, delay, distinctUntilChanged, filter, first, map, switchMap, takeWhile, tap} from 'rxjs/operators';
import {PlatformService} from '../../core/services/platform.service';
import {StaticContentService} from '../../core/services/static-content.service';
import {UserService} from '../../core/services/user/user.service';
import {GroupsService} from '../../core/services/groups.service';
import {combineLatest, Observable, of, ReplaySubject} from 'rxjs';
import {IHunt} from './base-promo.interface';
import {LocalHistoryService} from '../../core/services/local-history.service';
import {Router} from '@angular/router';
import {inject} from '@angular/core';
import {ModalsFromUrlService} from '../../core/modal-v2/modals-from-url.service';
import {ModalsFromUrlList} from '../../core/modal-v2/modals-from-url-list';
import {ModalService} from '../../core/modal-v2/modal.service';
import {UserBonusesService} from '../../core/services/user/user-bonuses.service';
import {StaticPageService} from '../static-page/static-page.service';
import {LootboxService} from '../../core/services/lootbox/lootbox.service';
import {TimeService} from '../../core/services/time.service';
import { DeviceDetectorService } from 'ngx-unificator/services';
import {ConfettiService} from '../../core/services/confetti.service';
import {GlobalEventsService} from '../../core/services/global-events.service';
import {WindowService} from "../../core/services/window.service";
import {TranslationService} from "../../core/shared/translation/translation.service";
import {ResponseHandlerService} from '../../core/services/response-handler.service';
import {CmsApiService} from '../../core/services/api/cms-api.service';
import {ArrayService} from '../../core/services/array.service';
import {GamesService} from '../../core/services/games/games.service';
import {BreakpointsDetectorService} from '../../core/services/breakpoints-detector.service';
import {LanguageService} from '../../core/services/language/language.service';
import {TournamentsService} from '../tournaments/tournaments.service';
import {CopyTextService} from "../../core/services/copy-text.service";

export enum EventType {
  First = 'first',
  Second = 'second',
  Third = 'third'
}

export interface WelcomePromoModal {
  Title: string;
  Description: string;
  LogoImage: string;
  MainImage: string;
  Prize: string;
  ButtonUrl: string;
}

export abstract class BasePromo {

  /**
   * Abstract event name for hunt(halloween, easter-eggs ...)
   */
  public abstract eventName: string;

  /**
   * Event type elements
   */
  public eventType = EventType;

  /**
   * Event collected list
   */
  public eventList: unknown[] = null;

  /**
   * Event Loaded
   */
  private _eventLoaded = null;

  /**
   * Show/hide sidebar menu link
   */
  public linkMenuEnabled: boolean;

  /**
   * Show/hide bottom menu link
   */
  public linkBottomEnabled: boolean;

  /**
   * Hunt modal data from cms
   */
  public huntModalData$: ReplaySubject<IHunt | any> = new ReplaySubject<IHunt | any>(1);

  public shouldHideModals$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  /**
   * Event group for hunt
   */
  public eventGroup: string;

  /**
   * Promo logo
   */
  public isPromoLogo: boolean;


  private _static: StaticContentService = inject(StaticContentService);
  private _modalsFromUrl: ModalsFromUrlService = inject(ModalsFromUrlService);
  private _events: GlobalEventsService = inject(GlobalEventsService);
  public array: ArrayService = inject(ArrayService);
  public breakpoint: BreakpointsDetectorService = inject(BreakpointsDetectorService);
  public platform: PlatformService = inject(PlatformService);
  public history: LocalHistoryService = inject(LocalHistoryService);
  public group: GroupsService = inject(GroupsService);
  public window: WindowService = inject(WindowService);
  public bonuses: UserBonusesService = inject(UserBonusesService);
  public user: UserService = inject(UserService);
  public page: StaticPageService = inject(StaticPageService);
  public lootboxService: LootboxService = inject(LootboxService);
  public time: TimeService = inject(TimeService);
  public tournaments: TournamentsService = inject(TournamentsService);
  public device: DeviceDetectorService = inject(DeviceDetectorService);
  public confetti: ConfettiService = inject(ConfettiService);
  public router: Router = inject(Router);
  public translate: TranslationService = inject(TranslationService);
  public responseHandler: ResponseHandlerService = inject(ResponseHandlerService);
  public cmsApi: CmsApiService = inject(CmsApiService);
  public games: GamesService = inject(GamesService);
  public lang: LanguageService = inject(LanguageService);
  public local: LocalHistoryService = inject(LocalHistoryService);
  public modals: ModalService = inject(ModalService);
  public copy: CopyTextService = inject(CopyTextService);
  public cookie: CookieService = inject(CookieService);


  public get eventEnable(): boolean {
    return this._eventLoaded;
  }

  public set eventEnable(enable) {
    this._eventLoaded = enable;
  }

  public get isFirstExist(): boolean {
    return this.user.auth && this._eventLoaded && !this.eventList.includes(this.eventType.First);
  }

  public get isSecondExist(): boolean {
    return this.user.auth && this._eventLoaded && !this.eventList.includes(this.eventType.Second);
  }

  public get isThirdExist(): boolean {
    return this.user.auth && this._eventLoaded && !this.eventList.includes(this.eventType.Third);
  }

  public initEvent() {
    if (this.platform.isBrowser) {
      this._static.item({slug: 'event-' + this.eventName}).pipe(
        tap((response: IHunt[]) => {
          if (Array.isArray(response)) {
            this.shouldHideModals$.next(!!response.length);
          }
          if (response && response.length) {
            this.huntModalData$.next(response[0]);
            this._resolveEvent(response[0]);
          }
        })
      ).subscribe();
    }
  }

  public addEventToCollection(type: EventType) {
    if (this.platform.isBrowser) {
      this.eventList.push(type);
      this.cookie.set(this.eventName + '-event', JSON.stringify(this.eventList), 999, '/');

      if (this.eventList.length > 2) {
        this.huntModalData$.pipe(
          first(),
          tap((data) => {
            if (this.eventList.length === 3) {
              this.group.addToGroup(data.BonusGroupCatch).subscribe();
            }
          }),
          switchMap((hunt: IHunt) => this.group.addToGroup(hunt.BonusGroup)),
          switchMap(() => {
            this.openHuntModal(type);
            return of(null);
          }),
          catchError((err) => {
            this.openHuntModal(type);
            console.log('err', err);
            return of(err);
          })
        ).subscribe();
      } else {
        this.openHuntModal(type);
      }
    }
  }

  private _resolveEvent(data: IHunt) {
    this._eventLoaded = null;
    this.eventList = [];
    if (data[0]) {
      this.huntModalData$.next(data[0]);
    }

    if ((this.user.auth && data && (!this.group.isExistGroup(data.BonusGroupClaimed)))) {
      this.cookie.check(this.eventName + '-event') ?
        this.eventList = JSON.parse(this.cookie.get(this.eventName + '-event')) :
        this.eventList = [];
      this._eventLoaded = true;
      this.eventGroup = data.BonusGroup;
    } else if (data && !this.user.auth) {
      this._eventLoaded = true;
    } else {
      this._eventLoaded = false;
    }
  }

  public initMenuLink(bottomLink = false) {
    this._static.item({slug: this.eventName + '-side-menu'})
      .pipe(
        tap((response: any[]) => {
          this.isPromoLogo = !!(response && response.length);
          if (!bottomLink) {
            this.linkMenuEnabled = !!(response && response.length);
          } else {
            this.linkBottomEnabled = !!(response && response.length);
          }

        })
      ).subscribe();
  }

  public async onDeposit(promoName, successUrl: string = this.window?.nativeWindow?.location?.href) {
    if (!this.user.auth) {
      this.history.addUrl('before-login', `promo/${promoName}`);
      this._modalsFromUrl.controller.open(ModalsFromUrlList.REGISTRATION);
    } else {
      await this.router.navigate(['account', 'deposit'], {
        queryParams: {
          successUrl,
        },
      });
    }
  }

  public onHuntClick() {
    this.user.auth$.pipe(
      tap(auth => {
        if (auth) {
          this.router.navigateByUrl('/').then();
        } else {
          this.user.authUser().then();
        }
      })
    ).subscribe();
  }

  public initWelcomePromoModal() {
    if (this.platform.isBrowser) {
      this._static.item({slug: this.eventName + '-welcome-modal'})
        .pipe(
          filter(response => !!response?.length),
          switchMap((response) => this._checkShouldOpenWelcomeModal$(response)),
        ).subscribe();
    }
  }

  private _checkShouldOpenWelcomeModal$(modalData) {
    const routesIgnore = ['403', '404', 'register', 'login', 'deposit', 'cashout', 'games', 'promo', 'play'];
    return this._events.routerNavigationEnd$
      .pipe(
        takeWhile(() => !this.cookie.check('welcome-modal-promo'), true),
        distinctUntilChanged(),
        delay(120000),
        filter(() => {
          const hasWelcomeModalCookie = this.cookie.check('welcome-modal-promo');
          const shouldOpenModal = this.user.auth && !hasWelcomeModalCookie;
          return shouldOpenModal && !routesIgnore.some(route => this.router.url.includes(route));
        }),
        switchMap(() => this.checkActivatedBonuses(modalData)),
      );
  }

  public abstract openHuntModal?(type: EventType): void;
  public abstract checkActivatedBonuses?(data: WelcomePromoModal);

}
