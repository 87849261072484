import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentTabRoutingModule } from './payment-tab-routing.module';

@NgModule({
  imports: [
    CommonModule,
    PaymentTabRoutingModule
  ]
})
export class PaymentTabModule {
}
