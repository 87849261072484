import {Injectable} from '@angular/core';
import {GameCategory} from '../../../core/services/games/game-category';
import {combineLatest, Observable, of} from 'rxjs';
import {ITournament} from '../../tournaments/tournaments.interface';
import {filter, first, map, share, switchMap, tap} from 'rxjs/operators';
import {BasePromo, WelcomePromoModal} from '../base-promo';
import {SummerPromoPage} from './summer.interface';
import {BonusStage} from '../../../core/services/user/data/user-bonuses.data';
import {BonusesStatuses} from '../../../core/services/groups.service';
import {USER_HAVE_ONE_DEPOSIT_GROUP, USER_RECEIVED_PHONE_BONUS} from '../../bonuses/bonuses.component';
import {UserPhoneService} from '../../../core/services/user/user-phone.service';
export const LAST_BONUS_ID = 'last-bonus';
export const LAST_WELCOME_BONUS_SPINNED = '--last-welcome-bonus-spinned';

export const PROMO_KEYS = {
  WELCOME_BONUS_KEYS: ['first', 'second', 'third'],
  BONUS_WHEEL_FS: 'WHEEL FS',
};

export const WHEEL_BONUSES = [
  {prize: 15, image: '/assets/img/promo/summer/prizes/1.png'},
  {prize: 20, image: '/assets/img/promo/summer/prizes/2.png'},
  {prize: 40, image: '/assets/img/promo/summer/prizes/3.png'},
  {prize: 50, image: '/assets/img/promo/summer/prizes/4.png'},
  {prize: 65, image: '/assets/img/promo/summer/prizes/5.png'},
  {prize: 80, image: '/assets/img/promo/summer/prizes/6.png'},
  {prize: 111, image: '/assets/img/promo/summer/prizes/7.png'},
  {prize: 'secret', image: '/assets/img/promo/summer/prizes/secret.png'},
];

/**
 * Enum defining different actions related to Easter promotion
 */
export enum SummerAction {
  GO_TO_DEPOSIT = 'deposit',
  TOURNAMENTS = 'tournaments',
  PROMO_AUTH = 'promo-auth',
  GET_BONUS = 'get-bonus',
  GO_TO_BONUSES = 'bonuses',
}

@Injectable({
  providedIn: 'root',
})
export class SummerService extends BasePromo {

  /**
   * Configuration for different actions and their corresponding routes
   */
  private _actionRoutesConfig = {
    [SummerAction.GET_BONUS]: {
      key: null,
      url: '/profile/account',
      authUrl: ['profile', 'account'],
    },
    [SummerAction.GO_TO_BONUSES]: {
      key: null,
      url: '/bonuses',
      authUrl: ['bonuses'],
    },
    [SummerAction.TOURNAMENTS]: {
      key: 'promo-tournaments',
      url: '/tournaments',
      authUrl: ['tournaments'],
    },
    [SummerAction.GO_TO_DEPOSIT]: {
      key: 'promo-deposit',
      url: '/profile/deposit',
      authUrl: ['/', this.lang.current, 'profile', 'deposit']
    },
    [SummerAction.PROMO_AUTH]: {
      key: 'promo-auth',
      url: '/promo/summer',
      authUrl: null,
    },
  };

  public eventName = 'summer';

  /**
   * Observable representing the Easter promotion data
   */
  public promo$: Observable<SummerPromoPage> = this._getPromo$();

  /**
   * Observable representing current tournaments related to Easter
   */
  public tournaments$: Observable<ITournament[]> = this._getTournaments$();

  /**
   * User claimed all welcome bonuses
   */
  public isUserCanClaimAllBonuses: boolean;

  /**
   * User already activate today lootbox
   */
  public isUserAlreadyActivateBox: boolean;

  /**
   * Is promo loaded
   */
  public isLoadedPromo: boolean;

  /**
   * Current fs prize
   */
  public currentPrize: any;

  public currentWelcomeBonus: { cash: any, fs: any };

  /**
   * Lootbox promo
   */
  public lootboxPromoBonus: any;

  public lastWelcomeId;

  public wheelPrizeImg: string;

  public isPhoneVerified$: Observable<boolean> = this.user.auth$.pipe(
    filter(auth => !!auth),
    switchMap(() => this._phone.getPhoneList()),
    map(() => {
      return !this._phone.verified && !this.group.isExistGroup(USER_RECEIVED_PHONE_BONUS) &&
        this.group.isExistGroup(USER_HAVE_ONE_DEPOSIT_GROUP) && this.bonuses.checkDifferenceMoreTwoDays();
    }),
    first(),
    share()
  );

  constructor(private _phone: UserPhoneService) {
    super();
  }

  get isWelcomeBonusExist(): boolean {
    return Boolean(this.currentWelcomeBonus.fs) || Boolean(this.currentWelcomeBonus.cash);
  }

  get isPhoneVerified(): boolean {
    return !this.bonuses.checkDifferenceMoreTwoDays() ||
      (this.group.isExistGroup(USER_HAVE_ONE_DEPOSIT_GROUP) || !this.group.isExistGroup(USER_RECEIVED_PHONE_BONUS));
  }

  public openHuntModal?(): void {
    throw new Error('Method not implemented.');
  }

  public checkActivatedBonuses(data: WelcomePromoModal): Observable<any> {
    return this.bonuses.summerActivatedBonusesFs$.pipe(
      map((activatedBonusesFs) => {
        return !Boolean(activatedBonusesFs.length);
      }),
      first(),
      tap(async (isShouldOpened) => {
        if (isShouldOpened) {
          const welcomeModal = await import(
            '../../../core/modal-v2/components/lazy/promo-modal/summer-welcome-modal/summer-welcome-modal.component'
            );
          await this.modals.openLazy(welcomeModal?.SummerWelcomeModalComponent, {data: data[0], template: "CLEAR"});
          this.cookie.set('welcome-modal-promo', 'true', 1, '/');
        }
      }),
    );
  }

  /**
   * Function to handle different actions related to Easter promotion
   * @param action The action to perform
   * @param bonusCode Optional bonus code
   * @param successUrl Optional success URL
   */
  public async onAction(
    action: SummerAction,
    bonusCode: string = '',
    successUrl: string = this.window.nativeWindow.location.href,
  ) {
    const routeConfig = this._actionRoutesConfig[action];
    const {key, url, authUrl} = routeConfig;

    switch (action) {
      case SummerAction.PROMO_AUTH:
        await this.user.authUser();
        break;
      case SummerAction.TOURNAMENTS:
      case SummerAction.GO_TO_BONUSES:
      case SummerAction.GET_BONUS:
        await this._handleActionRoute(key, url, authUrl);
        break;
      case SummerAction.GO_TO_DEPOSIT:
        await this._handleActionRoute(
          key,
          url,
          authUrl,
          {successUrl, bonusCode},
          {queryParams: {successUrl, bonusCode}},
        );
        break;
    }
  }

  public getWelcomeBonusImageByTitle(title: string): string {
    const lowerCaseTitle = title.trim().toLowerCase();
    if (lowerCaseTitle.includes('first')) {
      return '/assets/img/promo/summer/welcome/1.png';
    } else if (lowerCaseTitle.includes('second')) {
      return '/assets/img/promo/summer/welcome/2.png';
    } else if (lowerCaseTitle.includes('third')) {
      return '/assets/img/promo/summer/welcome/3.png';
    }
    return '';
  }

  /**
   * Get games list by category for bottom slider
   */
  public getGameListByCategory$(): Observable<any> {
    return this.cmsApi.gameList({category: GameCategory.SUMMER_GAMES})
      .pipe(
        map(list => this.array.toChunks(
          this.games.gameListMapper(
            this.array.checkAndCloneArray(list.data.gameList, this.breakpoint.belowSmallDesktop ? 4 : 5)),
          this.breakpoint.belowSmallDesktop ? 4 : 5)),
      );
  }

  /**
   * Function to handle routing for different actions
   * @param key The key for the action
   * @param url URL for non-authenticated users
   * @param authUrl URL for authenticated users
   * @param urlData Additional data for URL
   * @param extras Additional navigation options
   */
  private async _handleActionRoute(key: string, url: string, authUrl: string[], urlData?: any, extras?: any) {
    const urlParams = {key, url, authUrl, urlData, extras};
    if (!key) {
      await this.router.navigateByUrl(url);
      return;
    }
    if (!this.user.auth) {
      this.local.addUrl(urlParams.key, urlParams.url, urlParams.urlData);
      await this.user.authUser();
    } else {
      await this.router.navigate(urlParams.authUrl, urlParams.extras);
    }
  }

  /**
   * Private method to retrieve tournaments related to Easter.
   * @returns An Observable emitting tournaments related to Easter.
   */
  private _getTournaments$(): Observable<any> {
    return this.tournaments.list({with_game_list: 1}).pipe(
      filter((tournaments) => !!tournaments),
      map(tournaments => tournaments?.now?.filter(tournament => {
        return tournament?.slug.includes('summer');
      })),
    );
  }

  /**
   * Function to fetch Easter promotion data
   * @returns Observable of EasterPageTypes
   */
  private _getPromo$(): Observable<SummerPromoPage> {
    this.isLoadedPromo = false;
    this.isUserCanClaimAllBonuses = this.group.isExistGroup('ID75') || this.group.isExistGroup(BonusesStatuses.ALL_WELCOME_OFFERS_USED);
    if (this.isUserCanClaimAllBonuses) {
      this.cookie.delete(LAST_BONUS_ID);
    }
    return this.user.auth$.pipe(
      switchMap((auth) => this._fetchPromoData$(auth).pipe(
        map(([promo, lootbox, fs, bonuses]) =>
          this._processPromoData$(promo, lootbox, fs, bonuses)),
        tap((list) => {
          this._updateUserBonusInfo(list, this.isUserCanClaimAllBonuses);
          setTimeout(() => this.isLoadedPromo = true, 500);
        }),
      ))
    );
  }

  /**
   * Private method to update user bonus information based on the provided list.
   * @param list The list containing user bonus information.
   */
  private _updateUserBonusInfo(list: any, isWCMFinished?: boolean): void {

    this.currentWelcomeBonus = {
      cash: this._getFilteredCashWelcomeBonuses(list?.bonuses),
      fs: this._getFilteredFreespinsWelcomeBonuses(list?.fs),
    };

    const lastBonus = +this.cookie.get(LAST_BONUS_ID);

    if (this.currentWelcomeBonus.fs?.id === lastBonus || this.currentWelcomeBonus.cash?.id === lastBonus || (isWCMFinished && this.cookie.check(LAST_WELCOME_BONUS_SPINNED))) {
      this.currentWelcomeBonus = {
        cash: undefined,
        fs: undefined,
      };
    }

    this.lootboxPromoBonus = this._getFilteredLootboxBonuses(list?.lootbox);
    this.isUserAlreadyActivateBox = this._checkActivatedTodayLootbox(list?.lootbox);
  }

  private _getFilteredLootboxBonuses(arrayToCheck: any[]) {
    return arrayToCheck?.filter(lootbox => lootbox.lootboxTitle.trim().toLowerCase().includes('landing') &&
      lootbox.stage === BonusStage.ISSUED)[0];
  }

  private _getFilteredFreespinsWelcomeBonuses(arrayToCheck: any[]) {
    return arrayToCheck?.filter(bonus => bonus.stage === BonusStage.ISSUED && bonus.isWelcome)
      .sort((a, b) => b.created_at - a.created_at)[0];
  }

  private _getFilteredCashWelcomeBonuses(arrayToCheck: any[]) {
    return arrayToCheck?.filter(bonus => bonus.stage === BonusStage.HANDLE_BETS && bonus.isWelcome)
      .sort((a, b) => b.created_at - a.created_at)[0];
  }

  private _checkActivatedTodayLootbox(arrayTcCheck: any[]) {
    return arrayTcCheck?.some(l => l.stage === BonusStage.ACTIVATED && l.lootboxTitle.trim().toLowerCase().includes('landing') &&
      this.time.isTodayDate(new Date(l.created_at)));
  }

  /**
   * Function to process fetched Easter promotion data
   * @param promo Array of EasterPageTypes
   * @param lootbox
   * @param fs Array of free spins
   * @param bonuses
   * @returns Processed Easter promotion data
   */
  private _processPromoData$(promo: SummerPromoPage[], lootbox: any[], fs: any[], bonuses: any[]): any {

    return promo?.map(promoItem => {
      if (promoItem?.WheelPrizeImage && promoItem?.Prizes) {
        this._resolvePrizes(promoItem?.WheelPrizeImage, promoItem?.Prizes);
      }
      return {
        ...promoItem,
        fs,
        lootbox,
        bonuses,
        Steps: [...Object.values(promoItem?.Steps)],
        unpublishAt: this.time.toLocalDate(promoItem?.unpublishAt),
        publishAt: this.time?.toLocalDate(promoItem?.publishAt),
      }
    }).map(item => ({
      ...item,
    }))[0];
  }

  /**
   * Function to fetch Summer promotion data from API
   * @param mode Payment mode
   */
  private _fetchPromoData$(auth): Observable<[SummerPromoPage[], any[], any[], any[]]> {
    return combineLatest([
      this.page.item({slug: this.eventName}),
      auth ? this.lootboxService.loadUserLootBox() : of([]),
      auth ? this.bonuses.freeSpinsList() : of([]),
      auth ? this.bonuses.bonusList() : of([]),
    ]);
  }


  private _resolvePrizes(wheelPrizeImg: string, prizes: any) {
    this.wheelPrizeImg = wheelPrizeImg;
    [...Object.values(prizes)]?.forEach((p: any, i) =>  WHEEL_BONUSES[i].prize = (p?.value === 'secret' ? p?.value : +p?.value));
  }
}
