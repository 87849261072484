import { Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import { NEVER, Observable } from 'rxjs';
import { CmsApiService } from './api/cms-api.service';

@Injectable({
  providedIn: 'root'
})
export class CacheControlService {

  /**
   * Cache observable
   */
  private _cache$: Observable<any>;

  constructor(
    private _cmsApi: CmsApiService
  ) {
  }

  get cache$() {
    return this._cache$;
  }

  /**
   * Update cache
   */
  public updateCache() {
    this._cache$ = NEVER;
    return this._cache$ = this._cmsApi.getCacheCf().pipe(
      shareReplay(1),
    );
  }
}
