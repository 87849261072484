import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';
import { Payment } from 'src/app/core/vendor/ss-payments-v2/ss-payment-types';
import { PaymentSsComponent } from './components/payment-ss/payment-ss.component';

@UntilDestroy()
@Component({
  templateUrl: './payment-tab.component.html',
  styleUrls: ['./payment-tab.component.scss'],
  imports: [
    PaymentSsComponent
  ],
  standalone: true,
})
export class PaymentTabComponent implements OnInit {

  public Action: Payment.Action = null;

  public PaymentAction = Payment.Action;

  constructor(
    private _route: ActivatedRoute,
  ) {

  }

  ngOnInit(): void {
    this._route.parent.url.pipe(
      untilDestroyed(this),
      tap(e => {
        this.Action = (e[0].path as Payment.Action);
      })
    ).subscribe();
  }

}
